import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

export const LogoSymbol = ({...props}) => {
    return (
        <SvgIcon {...props} viewBox="0 0 140 72">
            <path d="M140 72H0V0H140V72ZM4 68H136V4H4V68Z" fill="black"/>
            <path d="M99.5 32.4C101.433 32.4 103 30.833 103 28.9C103 26.967 101.433 25.4 99.5 25.4C97.567 25.4 96 26.967 96 28.9C96 30.833 97.567 32.4 99.5 32.4Z" fill="black"/>
            <path d="M99.5 18.2C101.433 18.2 103 16.633 103 14.7C103 12.767 101.433 11.2 99.5 11.2C97.567 11.2 96 12.767 96 14.7C96 16.633 97.567 18.2 99.5 18.2Z" fill="black"/>
            <path d="M99.5 46.6C101.433 46.6 103 45.033 103 43.1C103 41.167 101.433 39.6 99.5 39.6C97.567 39.6 96 41.167 96 43.1C96 45.033 97.567 46.6 99.5 46.6Z" fill="black"/>
            <path d="M99.5 60.8C101.433 60.8 103 59.233 103 57.3C103 55.367 101.433 53.8 99.5 53.8C97.567 53.8 96 55.367 96 57.3C96 59.233 97.567 60.8 99.5 60.8Z" fill="black"/>
            <circle cx="39" cy="36" r="20" fill="black"/>
        </SvgIcon>
    )

}

export default LogoSymbol