import Fade from '@material-ui/core/Fade'
import LinearProgress from '@material-ui/core/LinearProgress'
import React from 'react'
import {withStyles} from "@material-ui/core";



const StyledLinearProgress = withStyles({
    colorPrimary: {
        backgroundColor: '#ffffff',
    },
    barColorPrimary: {
        backgroundColor: '#8c8c8c',
    },
})(LinearProgress);



export const ProgressIndicator = () => {
    return (
        <div>
            <Fade in={true}
                  style={{transitionDelay: '400ms'}}
                  unmountOnExit={true}>
                <StyledLinearProgress color={'primary'} />
            </Fade>
        </div>
    )
}
