import React from "react";
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    body: {
        [theme.breakpoints.up('md')]: {
            maxWidth: 600,
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 660,
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: 880,
        },
    },
}));

const ArticleContent = ({children}) => {
    const classes = useStyles();

    return (
        <div className={classes.body}>
            {children}
        </div>
    )
}

export default ArticleContent;