import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import {bpnAPI, museumsAPI} from "../app/axios";
import {
    ArticleBase,
    ArticleBody,
    ArticleContent,
    ArticleGrid,
    ArticleSection,
    ArticleSidebar,
} from '../components/article';
import Header from '../components/header/Header';
import HeaderExpanded from '../components/header/HeaderExpanded';
import Receipt from '../components/ticketValidator/Receipt';
import Footer from '../components/footer/Footer';
import BoughtTicketList from '../components/ticketValidator/BoughtTicketList';
import {useTranslation} from 'react-i18next';
import CustomerSpecificLogic from "../components/CustomerSpecificLogic";
import moment from "moment";

export const orderStatus = {
    CONFIRMED_CREDITED: "confirmed_credited",
}

const useStyles = makeStyles(theme => ({
    ticketsWrapper: {
        display: 'flex',
        flexFlow: 'wrap',
    },
    receiptViewWrapper: {
        width: "100%",
        backgroundColor: "white",
        display: "block",
    },
    sticky: {
        [theme.breakpoints.up('md')]: {
            "& > *": {
                marginTop: theme.spacing(-7),
            }
        },
        "&[data-sticky=true]": {
            position: "sticky",
            top: -96,
            zIndex: 2,
            [theme.breakpoints.up('md')]: {
                top: 0,
                marginTop: theme.spacing(-7),
                marginBottom: theme.spacing(7),
                "& > *": {
                    marginTop: 0,
                }
            }
        }
    },
}));

const ReceiptView = () => {
    const {externalId} = useParams()
    const classes = useStyles()
    const history = useHistory()
    const [order, setOrder] = useState()
    const { t } = useTranslation('portal')
    const [jsonManifest, setJsonManifest] = useState({})
    const [receiverFirstName, setReceiverFirstName] = useState("")
    const [receiverLastName, setReceiverLastName] = useState("")
    const [productReceiptData, setProductReceiptData] = useState()
    const [loading, setLoading] = useState(false)
    const colorHeader = productReceiptData?.colorHeader || 'red'
    const expanded = true
    const imageUrl = ''
    moment.locale(localStorage.getItem("i18nextLng"))


    const [museumSettings, setMuseumSettings] = useState();

    useEffect(() => {
        if (order) {
            async function getMuseumsSettingsAndUpdateManifest() {
                const myDynamicManifest = {
                    "name": "Museumsbillett",
                    "short_name": "Museumsbillett",
                    "start_url": `www.museumsbillett.no/receipt/${order.externalId}`,
                    "background_color": "#ffffff",
                    "theme_color": "#000000",
                    "display": "standalone",
                    "icons": [{
                        "src": "favicon.ico",
                        "sizes": "64x64 32x32 24x24 16x16",
                        "type": "image/x-icon"
                    }]
                }
                myDynamicManifest.name =  order.orderLines[0].productName
                myDynamicManifest.short_name = order.orderLines[0].productName

                const museumSettings = await  museumsAPI.get(`museums/${order.orderLines[0].museumId}`)
                if (museumSettings) {
                    myDynamicManifest.icons[0].src = museumSettings && museumSettings?.data?.museum?.profile !== "" ? `https://${museumSettings?.data?.museum?.profile}` : "favicon.ico"
                    setMuseumSettings(museumSettings?.data?.museum)
                }

                setJsonManifest(myDynamicManifest)
            }

            getMuseumsSettingsAndUpdateManifest()
        }
    }, [order])

    useEffect(() => {
        if(jsonManifest && order) {
            const stringManifest = JSON.stringify(jsonManifest)
            const blob = new Blob([stringManifest], {type: 'application/json'})
            const manifestURL = URL.createObjectURL(blob)
            document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL)
            document.querySelector('#page-title').innerHTML = order.orderLines[0].museumName + "-" + order.orderLines[0].productName
        }
    },[jsonManifest])


    useEffect(() => {
        if(museumSettings) {
            document.querySelector('#my-touch-icon').setAttribute('href', museumSettings?.profile !== "" ? `https://${museumSettings.profile}` : "")
        }
    },[museumSettings])

    function fetchTickets(token) {
        setLoading(true);
        setLoading(true);
        bpnAPI.get(`orders/tickets/${externalId}`, {params:{ 'captcha_token': token}}).then(order => {
            setOrder(order.data.order)
            bpnAPI.get(`${order.data.order.orderLines[0].productId}`,
                {
                    params: {
                        'receipt_data': true,
                    },
                }).then(res => {
                setProductReceiptData(res.data.product);
            }).catch(e => {
                console.log(e);
                setLoading(false);
            }).finally(() => setLoading(false));
        }).catch(e => {
            console.log(e)
            history.push('')
        });
    }

    useEffect(() => {
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${window._env_.RECAPTCHA_SITE_KEY}`, function () {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(window._env_.RECAPTCHA_SITE_KEY, { action: 'submit' }).then(token => {
                    fetchTickets(token);
                });
            });
        });
    }, [externalId]);

    useEffect(() => {
        if (order?.giftedToFirstName && order?.giftedToLastName) {
            setReceiverFirstName(order.giftedToFirstName)
            setReceiverLastName(order.giftedToLastName)
        } else if (order?.customer) {
            setReceiverFirstName(order.customer.firstName)
            setReceiverLastName(order.customer.lastName)
        }
    }, [order]);


    const loadScriptByURL = (id, url, callback) => {
        const isScriptExist = document.getElementById(id);

        if (!isScriptExist) {
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            script.onload = function () {
                if (callback) callback();
            };
            document.body.appendChild(script);
        }

        if (isScriptExist && callback) callback();
    }

    return (
        <>
            {order && !loading && (
                <div className={classes.receiptViewWrapper}>
                    <ArticleBase id='top'>
                        <Header
                            logoText={productReceiptData?.museumSettings?.screenName || order.orderLines[0].museumName}
                            backgroundColor={colorHeader}
                            expanded={expanded}
                        >
                            <HeaderExpanded
                                imageUrl={imageUrl}
                                title={
                                    productReceiptData?.type === 'seasonal_pass'
                                        ? `${t(
                                        'welcomeToUs'
                                        )} ${receiverFirstName} ${receiverLastName}, ${t(
                                        'hereAreYourProducts'
                                        )}`
                                        : `${t(
                                        'welcomeToUs'
                                        )} ${receiverFirstName} ${receiverLastName}, ${t(
                                        'hereAreYourTickets'
                                        )}`
                                }
                                description={
                                    order.status === orderStatus.CONFIRMED_CREDITED
                                        ? t('receiptViewSubHeadingCredited')
                                        : t('receiptViewSubHeading') + " " + order?.customer?.email
                                }
                                orderId={externalId || ''}
                            />
                        </Header>
                        <ArticleGrid>
                            <ArticleBody>
                                <ArticleContent>
                                    {
                                        <ArticleSection
                                            title={
                                                productReceiptData?.type === 'seasonal_pass'
                                                    ? t('receiptViewTitle')
                                                    : t('receiptViewTitleTickets')
                                            }
                                            description={
                                                productReceiptData?.type === 'seasonal_pass'
                                                    ? t('receiptViewSubTitle')
                                                    : t('receiptViewSubTitleTickets')
                                            }
                                        >
                                            <CustomerSpecificLogic productId={productReceiptData?.productId}/>
                                            <div className={classes.ticketsWrapper}>
                                                <BoughtTicketList order={order} productReceiptData={productReceiptData} museumSettings={museumSettings}/>
                                            </div>
                                        </ArticleSection>}
                                </ArticleContent>
                                <div className={classes.sticky} data-sticky={!true}>
                                    <ArticleSidebar>
                                        <ArticleContent>
                                            <Receipt order={order} product={productReceiptData} imageUrl={museumSettings && museumSettings?.profile !== "" ? `https://${museumSettings.profile}` : ""}/>
                                        </ArticleContent>
                                    </ArticleSidebar>
                                </div>
                            </ArticleBody>
                        </ArticleGrid>
                        <Footer logoText={productReceiptData?.museumSettings?.screenName || order.orderLines[0].museumName}/>
                    </ArticleBase>
                </div>)}
        </>
    )
}

export default ReceiptView
