import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { makeStyles } from '@material-ui/core/styles';
import ButtonEvent from "./EventButton"
import EventDateGrid from "./EventDateGrid";
import EventDetails from "./EventDetails";
import EventStatus from "./EventStatus";


moment.locale('nb')

const useStyles = makeStyles(theme => ({
    event: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        width: "100%",
    },
    media: {
        flexBasis: 0,
        minWidth: 96,
        width: 96,
        height: 96,
    },
    image: {
        display: "block",
        width: "100%",
    },
    status: {
        margin: theme.spacing('auto',1)
    }
}));

const EventListItem = ({imageUrl, date, title, ...props}) => {
    const classes = useStyles()

    return (
        <ButtonEvent {...props}>
            <div className={classes.event}>
                <EventDateGrid date={date} status={props.status}/>
                {imageUrl && <div className={classes.media}>
                    <img
                        className={classes.image}
                        src={imageUrl}
                        alt=""
                    />
                </div>}
                <EventDetails {...props} title={title} />
                <EventStatus {...props} status={props.status} className={classes.status} />
            </div>
        </ButtonEvent>
    )

}

EventListItem.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    date: PropTypes.string,
    location: PropTypes.string,
    onClick: PropTypes.func,
    status: PropTypes.oneOf(["full","limit", undefined]),
    statusLabel: PropTypes.string,
    disabled: PropTypes.bool,
}

export default EventListItem