import React, {useState} from 'react';
import {styled} from '@material-ui/core';
import ButtonSelector from "./ButtonSelector";

const FieldLabelContainer = styled('div')({
    width: '100%',
    textAlignLast: 'left',
});

const ButtonGroupContainer = styled('div')({
    width: '100%',
    textAlign: 'left',
});

const SelectorTitle = styled('div')({
    textAlign: 'left',
});

const Selector = ({
                      id,
                      schema,
                      formData,
                      disabled,
                      required,
                      readonly,
                      onChange,
                      rawErrors,
                      multiple = false,
                      formContext
                  }) => {

    const payTypes = schema.payTypes;
    const cartTotal = schema.cartTotal;
    const [value, setValue] = useState(null);

    const handleCurrentSelectionChanged = (value) => {
        setValue(value);
        onChange((value) || null);
    };

    if (cartTotal === 0 || !payTypes || payTypes?.length <= 1 ) {
        return null;
    }

    return (
        <>
            <FieldLabelContainer>
                <SelectorTitle>Betalingsmetode</SelectorTitle>
            </FieldLabelContainer>
            <ButtonGroupContainer>
                {payTypes.map((option) => (
                    <ButtonSelector
                        key={option.id}
                        onClick={() => handleCurrentSelectionChanged(option)}
                        isSelected={option?.id === value?.id}>
                        {option.name}
                    </ButtonSelector>
                ))}
            </ButtonGroupContainer>
        </>
    );
};

export default Selector;

