import React from 'react';
import moment from 'moment';
import "moment/locale/nb";
import "moment/locale/nn";
import "moment/locale/sv";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    
    weekday: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: 14,
        fontWeight: "bold",
        color: theme.palette.text.secondary,
        textTransform: "capitalize",
        padding: theme.spacing(1),
        lineHeight: 1,
    },
    suffix: {
        display: "none",

        [theme.breakpoints.up('md')]: {
            display: "inline",
        }
        
    }


}));

const CalendarWeekday = ({date}) => {
    const classes = useStyles()

    const prefix = moment(date).format('dddd').substr(0,2)
    const suffix = moment(date).format('dddd').substr(2)

    return (
        <div className={classes.weekday}>
            <span className={classes.prefix}>{prefix}</span>
            <span className={classes.suffix}>{suffix}</span>
        </div>
    )
}

export default CalendarWeekday;