import React, {useEffect, useState} from 'react';

import StepCalendar from "./steps/StepCalendar";
import StepTickets from "./steps/StepTickets";
import StepRegister from "./steps/StepRegister";
import TimeoutDialog from "./TimeoutDialog";
import {CheckoutSteps} from "../declarations/Checkoutsteps";

const MINUTES_TO_TIMEOUT = 10;

const Checkout = (props) => {
    const step = props.step
    const [currentStep, setCurrentStep] = useState("");
    const [reservationTimer, setReservationTimer] = useState();
    const [showTimeoutDialog, setShowTimeoutDialog] = useState(false);
    const handleCloseTimeoutDialog = () => {
        props.onCloseTimeoutDialog()
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (currentStep === CheckoutSteps.CALENDAR && step === CheckoutSteps.TICKETS) {
            clearTimeout(reservationTimer);
            setReservationTimer(setTimeout(() => setShowTimeoutDialog(true)
                , 60000 * MINUTES_TO_TIMEOUT))
            setShowTimeoutDialog(false)
        }
        setCurrentStep(step);
    }, [step])


    switch (step) {
        case CheckoutSteps.CALENDAR:
            return <StepCalendar {...props}/>
        case CheckoutSteps.TICKETS:
            return(
                <>
                    <TimeoutDialog onClose={handleCloseTimeoutDialog} open={showTimeoutDialog} timeOutAfter={MINUTES_TO_TIMEOUT}/>
                    <StepTickets {...props}/>
                </>)
        // case "addons":
        //     return <CheckoutAddons />
        case CheckoutSteps.REGISTER:
            return (
                <>
                    <TimeoutDialog onClose={handleCloseTimeoutDialog} open={showTimeoutDialog} timeOutAfter={MINUTES_TO_TIMEOUT}/>
                    <StepRegister {...props}/>
                </>
            )
    }
}

export default Checkout