import React from "react"
import { makeStyles } from '@material-ui/core/styles';

import moment from "moment"
import "moment/locale/nb";
moment.locale('nb')


const useStyles = makeStyles(theme => ({
    date: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        height: 96,
        padding: theme.spacing(1),
    },
    weekday: {
        fontFamily: "Akkurat, sans-serif",
        fontWeight: "bold",
        fontSize: 14,
        textTransform: "capitalize"
    },
    day: {
        fontFamily: "Akkurat mono, monospace",
        fontStyle: "normal",
        fontSize: 36,
        letterSpacing: -1
    },
    month: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: 12,
        fontStyle: "normal",
    },

}));

const EventDateGrid = (props) => {
    const classes = useStyles()

    if (!props.date) {
        return false
    }

    const weekday = props.date && moment.utc(props.date).format('dddd')
    const month = props.date && moment.utc(props.date).format('MMMM')
    const day = props.date && moment.utc(props.date).format('D')

    return (
        <div className={classes.date}>
            <div className={classes.weekday}>{weekday}</div>
            <div className={classes.day}>{day}</div>
            <div className={classes.month}>{month}</div>
        </div>
    )

}

export default EventDateGrid