import React, { useState } from "react"
import ProgramHeader from "./ProgramHeader"
// import ProgramTickets from "./ProgramTickets"
import ProgramBase from ".//ProgramBase"

const ProgramListItem = (props) => {
    const { preview = false, bookable, bookings, onChange, capacity = true } = props

    const [expanded, setExpanded] = useState(false)

    const _onToggle = () => {
        setExpanded((prevState) => !prevState)
    }

    const disabled = !capacity

    if (preview) {
        return (
            <ProgramBase {...props} disabled={disabled} expanded={expanded}>
                <ProgramHeader {...props} preview={true} description={false} />
            </ProgramBase>
        )
    }

    // if (bookings && !preview) {
    //
    //     return (
    //         <ProgramBase {...props} disabled={disabled} expanded={expanded}>
    //             <ProgramHeader {...props} expanded={expanded} disabled={disabled} onClick={!disabled && _onToggle} />
    //             { expanded && <ProgramTickets items={bookings} onChange={onChange} /> }
    //         </ProgramBase>
    //     )
    //
    // }

    return (
        <ProgramBase {...props} disabled={disabled} expanded={expanded}>
            <ProgramHeader {...props} description={false} />
        </ProgramBase>
    )


}

export default ProgramListItem