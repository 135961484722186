import React from "react";

import TextField from "@material-ui/core/TextField";

import { utils } from "@rjsf/core";
import {makeStyles} from "@material-ui/core/styles";
const { getDisplayLabel } = utils;

const TextWidget = ({
    id,
    required,
    readonly,
    disabled,
    type,
//    label,
    value,
    onChange,
    onBlur,
    onFocus,
    autofocus,
    options,
    schema,
    uiSchema,
    rawErrors = [],
    formContext,
    ...props
}) => {

    const _onChange = (event) => onChange(event.target.value === "" ? options.emptyValue : event.target.value);
    const _onBlur = (event) => {
        onBlur(id, event.target.value);
        onChange(event.target.value.trim())
    }
    const _onFocus = (event) => onFocus(id, event.target.value);

    const displayLabel = getDisplayLabel(
        schema,
        uiSchema
        /* TODO: , rootSchema */
    );

    const label = options.title || props.title || props.label || schema.title
    const variant = options.variant || "filled"
    const size = options.size
    const helperText = options.help || null

    const handlePaste = (e) => {
        if (schema.repeatField) {
            e.preventDefault();
            value = "";
        }
    };


    return (
        <TextField
            {...props}
            id={id}
            variant={variant}
            size={size}
            fullWidth={true}
            label={displayLabel && label}
            helperText={helperText}
            autoFocus={autofocus}
            required={required}
            disabled={disabled || readonly}
            onPaste={handlePaste}
            type={type || schema.type}
            value={value || value === 0 ? value : ""}
            error={rawErrors.length > 0}
            onChange={_onChange}
            onBlur={_onBlur}
            onFocus={_onFocus}
            inputProps={{'inputProps': {}}}
            InputLabelProps={{
                'inputLabelProps': {fontFamily: 'Akkurat, sans-serif',}
            }}
        />
    );
};

export default TextWidget;