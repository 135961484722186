import React from "react"
import EventButton from "./EventButton"
import EventDateGrid from "./EventDateGrid";


const EventGridItem = (props) => {

    return (
        <EventButton {...props} width="auto">
            <EventDateGrid {...props} />
        </EventButton>
    )


}

export default EventGridItem