import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import ProgramTimeSlot from ".//ProgramTimeSlot"
import ProgramStatus from ".//ProgramStatus"
import ButtonBase from  "@material-ui/core/ButtonBase"

const useStyles = makeStyles(theme => ({
    button: {
        fontSize: "1em"
    },
    header: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexGrow: 1,
        margin: theme.spacing(1.5),
        minHeight: "3em",

        fontFamily: "Akkurat, sans-serif",
        fontSize: "1em",
        lineHeight: 1.5,

        "&[data-preview=true]": {
            fontSize: 16,
            flexDirection: "column",

            "& $status": {
                position: "absolute",
                top: 0,
                right: 0,
            }

        },

        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",

            "& $status": {
                position: "absolute",
                top: 0,
                right: 0,
            }

        },

    },
    status: {
        height: "3em",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
    },
    content: {
        flexGrow: 1,

        flexDirection: "column",
        justifyContent: "flex-start",
        textAlign: "left",


    },

    title: {
        fontWeight: "bold",
    },
    description: {
        color: theme.palette.text.secondary,
    },

}));

const ProgramHeader = ({className, preview, expanded, title, description, onClick, ...props}) => {

    const classes = useStyles()

    if (onClick) {
        return (
            <ButtonBase className={className || classes.button} onClick={onClick}>
                <header className={className || classes.header} data-preview={preview}>
                    <ProgramTimeSlot {...props} />
                    <div className={classes.content}>
                        <div className={classes.title}>
                            { title }
                        </div>
                        { description && 
                            <div className={classes.description}>
                                { description }
                            </div>
                        }
                    </div>
                    <div className={classes.status}>
                        <ProgramStatus {...props} expanded={expanded} />
                    </div>
                </header>
            </ButtonBase>
        )
    }

    return (
        <header className={className || classes.header} data-preview={preview}>
            <ProgramTimeSlot {...props} />
            <div className={classes.content}>
                <div className={classes.title}>
                    { title }
                </div>
                { description && 
                    <div className={classes.description}>
                        { description }
                    </div>
                }
            </div>

            <div className={classes.status}>
                <ProgramStatus {...props} expanded={expanded} />
            </div>

        </header>
    )

}

export default ProgramHeader