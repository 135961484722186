import React from "react";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {utils} from "@rjsf/core";
import {FormControl, Radio, RadioGroup, Typography} from "@material-ui/core";

const { schemaRequiresTrueValue } = utils;

const CheckboxesWidget = (props) => {
    const {
        schema,
        id,
        value,
        disabled,
        readonly,
        //    label,
        autofocus,
        onChange,
        onBlur,
        onFocus,
        required,
        rawErrors,
        options
    } = props;

    const schemaRequiresTrue = schemaRequiresTrueValue(schema);

    const _onCheckboxChange = (e, option) => {
        let values = value
        if (e.target.checked) {
            values = [...values, option.label]
        } else {
            values  = values.filter(value => value !== option.label)
        }

        onChange(values.length > 0 ? values : undefined)
    };

    const handleRadioButtonChange = (event) => {
        onChange([event.target.value]);
    };

    const _onBlur = ({ target: { value } }) => onBlur(id, value);
    const _onFocus = ({ target: { value } }) => onFocus(id, value);
    return (
        <>
            {schema.title && <Typography style={rawErrors?.length > 0 ? {
                textDecorationLine: "underline",
                color: "#e03f3f"
            } : {}}>{required ? `${schema.title}*` : schema.title}</Typography>}
            {options.multipleChoice ?
                <>
                {options.enumOptions.map(option => {
                        return (
                            <FormControlLabel key={option.label}
                                              control={<Checkbox
                                                  color={"default"}
                                                  id={option.label}
                                                  checked={value.indexOf(option.value) !== -1}
                                                  required={schemaRequiresTrue}
                                                  disabled={disabled || readonly}
                                                  autoFocus={autofocus}
                                                  onChange={(e) => _onCheckboxChange(e, option)}
                                                  onBlur={_onBlur}
                                                  onFocus={_onFocus}
                                              />
                                              }
                                              label={<span>{option.value}</span>}/>)
                    })}
                </>
                :
                <FormControl component="fieldset">
                    <RadioGroup value={value[0]} onChange={handleRadioButtonChange} row>
                        {options.enumOptions.map(option =>
                            <FormControlLabel value={option.value} control={<Radio required={required} color={"default"} />} label={option.label} />
                        )}
                    </RadioGroup>
                </FormControl>}
        </>
    )
}

export default CheckboxesWidget;
