import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    status: {
        border: "1px solid",
        borderRadius: 12,

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",

        fontFamily: "Akkurat, sans-serif",
        fontSize: 12,
        fontWeight: "bold",
        lineHeight: 1,

        padding: theme.spacing(.75, 1.5),

        "&[data-status=limit]": {
            borderColor: "red",
            backgroundColor: "red",
            color: "white"
        }

    },

}));

const EventStatus = ({className, status=null, statusLabel, label}) => {
    const classes = useStyles()

    if (status === 'full') {
        statusLabel = statusLabel || "Utsolgt"
    } else if (status === 'limit') {
        statusLabel = statusLabel || "Få plasser!"
    } else if (typeof status === "number") {
        statusLabel = "Ledig: " + status
    }

    if (!status) {
        return false
    }

    return (
        <div className={className}>
            <Typography noWrap className={classes.status} data-status={status}>
                {statusLabel}
            </Typography>
        </div>
    )

}

export default EventStatus