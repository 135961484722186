import React, {useState} from "react"
import {makeStyles} from '@material-ui/core/styles';
import Color from 'color';
import LanguageIcon from '@material-ui/icons/Language';
import {ReactComponent as NoFlag} from "../../assets/flags/no.svg";
import {ReactComponent as EnFlag} from "../../assets/flags/gb.svg";
import {FormControl, Input, ListItemIcon, ListItemText, MenuItem, Select} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import i18n from "i18next";


const getContrast = (color) => {

    color = Color(color);

    if (color.isDark()) {
        return "white"
    } else {
        return "black"
    }

}

const useStyles = makeStyles(theme => ({
    languageSelector: {
        width:'4em',
        float: 'right',
        marginLeft: '10px'
    },
    flag: {
        width:'2em'
    }

}));

const LanguageSelector = ({backgroundColor, availableLanguages = []}) => {
    const [languageModalOpen, setLanguageModalOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const color = backgroundColor && getContrast(backgroundColor)
    const classes = useStyles({backgroundColor, color})

    const languages = [
        {lang: 'Norsk', shortName:'nb', icon: <NoFlag className={classes.flag}/>} ,
        {lang: 'English', shortName:'en', icon: <EnFlag className={classes.flag}/>} ,
    ]


    const changeI18NLanguage = (event) => {
        const value = event.target.value || []
        setSelectedLanguage(value)
        i18n.changeLanguage(value)
    }

    if (availableLanguages.length <= 0) {
        return null
    }

    return (
        <>
            <FormControl className={classes.languageSelector}>
                <IconButton id="filterButton" aria-label="filter" onClick={() => setLanguageModalOpen(true)}>
                    <LanguageIcon style={{color: color, transform: 'scale(1.8)'}}/>
                </IconButton>
                <Select
                    value={selectedLanguage}
                    style={{ display: "none"}}
                    open={languageModalOpen}
                    onClose={() => setLanguageModalOpen(false)}
                    onChange={changeI18NLanguage}
                    input={<Input />}
                    MenuProps={{
                        anchorEl: document.getElementById("filterButton")
                    }}>
                    {languages.map((language) => (
                        <MenuItem key={language.lang} value={language.shortName}>
                            <ListItemIcon>
                                {language.icon}
                            </ListItemIcon>
                            <ListItemText primary={language.lang} />

                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    )
}

export default LanguageSelector