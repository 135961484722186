import React from 'react';
import moment from 'moment';
import "moment/locale/nb";
import "moment/locale/nn";
import "moment/locale/sv";

import { makeStyles } from '@material-ui/core/styles';

import CalendarHeader from "./CalendarHeader"
import CalendarWeekday from "./CalendarWeekday"
import CalendarDay from "./CalendarDay"
import {ProgressIndicator} from "../../app/ProgressIndicator";

const useStyles = makeStyles(theme => ({
    calendar: {
        width: "100%",
        margin: "auto",
    },
    table: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        padding: 5, // props => { return props.spacing && theme.spacing(props.spacing/2)},
    },
    head: {
//        borderTop: "1px solid",
//        borderColor: theme.palette.divider,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",

        "& > * > * + *": {
            borderColor: "transparent"
        }

    },
    body: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",

        "& > *": {
            borderTop: "1px solid",
            borderColor: theme.palette.divider,

            "& > * + *": {
                borderLeft: "1px solid",
                borderColor: theme.palette.divider
            }
    
        }

    },
    row: {
        display: "flex",
        width: "100%",

        "& > *": {
            display: "block",
            flexBasis: 0,
            flexGrow: 1,
//            margin: 1 // props => { return props.spacing && theme.spacing(props.spacing/2)},
        },



    },

}));

const getCalendar = ({date, days = 1, minDate, maxDate, items, format = 'YYYY-MM-DD'}) => {

    const today = moment().format(format)
    const selectedDate = date && date.length === 10 && moment(date).format(format)
    const minDt = minDate && moment(minDate).format(format)
    const dt = date || minDt || today;

    const selectFrom = selectedDate
    const selectTo = selectFrom && moment(selectFrom).add(days, "days").format(format)

    const monthStart = moment(dt).startOf('month').format(format);
    const monthEnd = moment(dt).endOf('month').format(format);

    const dtStart = moment(dt).startOf('month').startOf('isoWeek').format(format);
    const dtEnd = moment(dt).endOf('month').endOf('isoWeek').format(format);
    const weeks = moment(dtEnd).diff(dtStart, 'week');

    let rows = [];

    for (let w = 0; w <= weeks; w++) {
        let datetime = moment(dtStart).add(w, 'weeks');

        datetime = moment(datetime).set('hour', 0);
        datetime = moment(datetime).set('minute', 0);
        datetime = moment(datetime).set('second', 0);
        
        let cols = [];

        let week = datetime

        for (let d = 0; d <= 6; d++) {
            datetime = moment(week).add(d, 'days');

            const date = moment(datetime).format(format)
            const isToday = moment(datetime).isSame(today, 'day')
            const isCurrentMonth = moment(datetime).isBetween(monthStart, monthEnd, undefined, '[]')


            const selected = date && moment(date).isBetween(selectFrom, selectTo, undefined, '[)')

            const itemsByDate = items && items.filter(item => item.date === date);

            let status, selectable

            if (itemsByDate.length) {

                selectable = true
                status = "full"

                itemsByDate.forEach(item => {
                    if (item.status !== "full") {
                        status = undefined                    
                    }
                })
    
            }



    	    cols.push({
                isToday: isToday,
                isCurrentMonth: isCurrentMonth,
                selectable: selectable,
                selected: selected,
                date: date,
                status: status
//                ...item
            })
        }

	    rows.push(cols)
    }
    
    return {
        monthStart: monthStart,
        dt: dt,
        selectFrom: selectFrom,
        selectTo: selectTo,
        dtStart: dtStart,
        dtEnd: dtEnd,
        rows: rows,
    }
    
}


const Calendar = ({date, days, minDate, maxDate, items = [], spacing = 1, onSelect, loadingCalendar, hasLaterEvents, hasEarlierEvents}) => {

    const { selectFrom, selectTo, monthStart, rows } = getCalendar({date, days, minDate, maxDate, items})

    const classes = useStyles({spacing: spacing})

    if (!rows.length) {
        return false
    }

    return (
        <div className={classes.calendar}>
            <CalendarHeader date={monthStart} onSelect={onSelect} hasLaterEvents={hasLaterEvents} hasEarlierEvents={hasEarlierEvents}  />
            {loadingCalendar && <ProgressIndicator/>}
            <div className={classes.table}>
                <div className={classes.head}>
                    <div className={classes.row}>
                        { rows && rows[0].map(col => (
                            <div className={classes.cell} key={col.date}>
                            <CalendarWeekday {...col} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className={classes.body}>
                { rows && rows.map((row, r) => (
                    <div className={classes.row} key={r}>
                        { row.map(col => {

                            return (
                                <div className={classes.cell} key={col.date}>
                                <CalendarDay {...col} onClick={() => onSelect(col)} />
                                </div>
                            )
                        })}
                    </div>
                ))}
                </div>
            </div>
        </div>
    )

}

export default Calendar;
