import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { museumNs, museumTranslationKey } from "../utils/staging"
import {useDeepCompareEffectNoCheck} from 'use-deep-compare-effect'

export const useProductTranslated = ({museumId, product}) => {
    const [response, setResponse] = useState({
        ...product
    })


    const getTranslatedTickets = (product) => {
        return product.tickets?.items.map(ticket => {
            return {
                title: ticket.title ? ticket.title : "",
                description: ticket.description ? ticket.description : null,
                productGroupName: ticket.productGroupName,
                id:  ticket.id,
                price:  ticket.price,
                priceCategoryId:  ticket.priceCategoryId,
                groupTicket:  ticket.groupTicket,
                productGroupId:  ticket.productGroupId,
                productPriceCategoryId:  ticket.productPriceCategoryId,
                excludedFromCapacityCalculation:  ticket.excludedFromCapacityCalculation,
                vat:  ticket.vat,
            }
        } )
    }

    const translateEvents = (events) => {
        return events?.map(event => ({...event, title: event.title}))
    }

    useDeepCompareEffectNoCheck(
        () => {
            if(museumId && product.title !== undefined)  {
                setResponse(r => ({
                    ...r,
                    title: product.title ? product.title : "",
                    description: product.description ? product.description : "",
                    tickets: {title:product.tickets.title, items: getTranslatedTickets(product)},
                    registration: {...product.registration, title: product.registration.title, description: product.registration.description},
                    receipt: {title: product.receipt.title, description: product.receipt.description},
                    calendar: {events: translateEvents(product.calendar.events)},
                }))
            }
        },
        [museumId, product]
    )

    return response
}
