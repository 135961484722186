import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import ButtonStatus from "./event/EventButton"

import EventTimeGrid from "./event/EventTimeGrid";
import EventDetails from "./event/EventDetails";
import EventStatus from "./event/EventStatus";
import EventDateGrid from "./event/EventDateGrid";


const useStyles = makeStyles(theme => ({
    slot: {
        width: "100%",
        minHeight: 96,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    status: {
        margin: theme.spacing('auto',1)
    }

}));

const EventListButton = ({className, selected, onClick, ...props}) => {
    const classes = useStyles()

    return (
        <ButtonStatus {...props} selected={selected} onClick={onClick}>
            <div className={classes.slot}>
                <EventDateGrid date={props.date} status={props.status}/>
                <EventDetails {...props} />
                <EventStatus {...props} className={classes.status} />
            </div>
       </ButtonStatus>
    )

}

export default EventListButton