import React from "react"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from '@material-ui/core/styles';
import LogoSymbol from "./LogoSymbol";


const useStyles = makeStyles(theme => ({
    logo: {
        fontSize: props => { return props.fontSize },
        position: "relative",
        display: "flex",
        flexDirection: props => { return props.flexDirection },
        justifyContent: "flex-start",
        alignItems: "center"
    },
    logoSymbol: {
        fontSize: "3.6em",
        height: "auto",
        "& > *": {
            fill: "currentColor",
        }
    },
    logoText: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: props => { return props.logoTextSize },
        lineHeight: 1.2,
        fontWeight: "bold",
        color: "currentColor",
        margin: props => { return props.logoTextMargin },
    },
}));

const Logo = ({variant = "landscape", flexDirection = "row", className, fontSize = "inherit", date, logoText = "Museumsbillett", logoTextSize = "1em", logoTextMargin = "0 .5em", onClick}) => {

    if (variant === "portrait") {
        flexDirection = "column"
        logoTextSize = ".75em"
        logoTextMargin = ".5em"
    }

    const classes = useStyles({flexDirection, fontSize, logoTextSize, logoTextMargin})

    return (
        <div className={className || classes.logo} onClick={onClick}>
            <LogoSymbol date={date} className={classes.logoSymbol} />
            <Typography className={classes.logoText}>{logoText}</Typography>
        </div>
    )

}

export default Logo