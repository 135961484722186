import React, {useRef} from "react"
import PropTypes from "prop-types"

import EventList from "../event/EventList"
import EventGrid from "../event/EventGrid"
import SlotList from "../SlotList"
import Datepicker from "../Datepicker/Datepicker";

import ArticleSection from "../article/ArticleSection"
import moment from "moment"
import _ from "lodash"
require("moment/min/locales.min");


const templates = {
    "list": EventList,
    "grid": EventGrid,
    "calendar": Datepicker,
}

const SelectEvent = ({
                         layout = "list",
                         events = [],
                         useSlots = false,
                         slotsTitle = "Velg tidspunkt",
                         maxItems,
                         onSelect,
                         id,
                         date,
                         days,
                         loadingCalendar,
                         activeCalendarMonth,
                         hasLaterEvents,
                         hasEarlierEvents,
                     }) => {

    const slotListWrapper = useRef(null)

    const scrollToRef = (ref) => {
        if (ref.current) {
            window.scrollTo({
                top: ref.current.offsetTop,
                behavior: 'smooth',
        })}
    }

    const _onSelect = (props) => {
        const {date, id, title, location} = props
        if (id) {
            onSelect && onSelect({date, id, title, location})
        } else {
            onSelect && onSelect({date})
        }
    }

    let eventItems = [], currentDate = date, currentId
    // TODO: remove this?
    events && events.forEach((item, index) => {

        if (!item.id) {
            item.id = index+1
        }

        let hidden = false
        if (hidden) {
            return false
        }

        if (id === item.id) {
            currentId = item.id
            currentDate = item.date
        }

        eventItems.push({
            ...item,
            selectable: true,
            selected: currentId === item.id,
        })
    })

    // sort events

    let sortedEvents = _.orderBy(eventItems, 'dtStart', 'asc');

    if (maxItems && sortedEvents.length > maxItems) {
        sortedEvents = sortedEvents.slice(0, maxItems)
    }

    // get date items

    const minDate = moment(sortedEvents[0]?.date)
    const maxDate = moment(sortedEvents[sortedEvents.length-1]?.date)

    // layout === "slots"

    if (layout === "slotsByDate") {
        const slotItems = _.groupBy(sortedEvents, 'date')
        return (
            <>
                {Object.keys(slotItems).length < 1 && <h5>Det finnes ingen planlagte hendelser for dette arranangementet</h5>}
                { Object.keys(slotItems).map((date, index) => {
                    moment.locale(localStorage.getItem("i18nextLng"))
                    const dateLabel = moment(date).format("dddd Do MMMM YYYY")
                    return (
                        <ArticleSection  key={index} subtitle={dateLabel}>
                            <SlotList items={slotItems[date]} onSelect={_onSelect} />
                        </ArticleSection>
                    )
                })}
            </>
        )
    }

    if (layout === "calendar") {
        useSlots = true
    }

    // use slots

    const slotItems = currentDate && sortedEvents && sortedEvents.filter(item => item.date === currentDate)

    if (useSlots && slotItems && slotItems.length) {

        const Template = (templates && templates[layout]) || templates["grid"]
        return (
            <>
                {Object.keys(slotItems).length < 1 && <h5>Det finnes ingen planlagte hendelser for dette arranangementet</h5>}
                { (Template && <Template date={currentDate} days={days} minDate={minDate} maxDate={maxDate} items={sortedEvents} onSelect={_onSelect} hasLaterEvents={hasLaterEvents} hasEarlierEvents={hasEarlierEvents} /> )|| <p>No template for {layout}</p> }

                <div ref={slotListWrapper}>
                    {scrollToRef(slotListWrapper)}
                    { slotItems &&
                    <ArticleSection subtitle={slotsTitle}>
                        <SlotList items={slotItems} onSelect={_onSelect} />
                    </ArticleSection>
                    }
                </div>
            </>
        )
    }

    // template
    const Template = (templates && templates[layout]) || templates["list"]

    // select event
    return (
        <>
            { (Template && <Template date={currentDate} days={days} minDate={activeCalendarMonth.startOf('month')}
                                     maxDate={activeCalendarMonth.endOf('month')} items={sortedEvents} onSelect={_onSelect} loadingCalendar={loadingCalendar} hasLaterEvents={hasLaterEvents} hasEarlierEvents={hasEarlierEvents}/>) || <p>No template for {layout}.</p> }
        </>
    )
}

SelectEvent.propTypes = {
    id: PropTypes.string,
    date: PropTypes.string,
    days: PropTypes.number,
    onSelect: PropTypes.func
}

export default SelectEvent