import React from "react"

// import NewsletterForm from "../Newsletter/NewsletterForm"
import EventList from "./event/EventList";
import ProgramList from "./program/ProgramList";
import ArticleSection from "./article/ArticleSection";


const CheckoutSections = ({
    date,
    cart,
    tickets,
    sections= [],
    program = {},
    ...props
}) => {

    return (
        <>
            { sections && sections.map((section, index) => {

                const { sectionType, title } = section

                // if (sectionType === "newsletter") {
                //     return (
                //         <ArticleSection {...section} subtitle={title} title={false} key={index}>
                //             <NewsletterForm {...section} formData={{email:email}} />
                //         </ArticleSection>
                //     )
                // }

                if (sectionType === "events") {
                    return (
                        <ArticleSection {...section} subtitle={title} title={false} key={index}>
                            { section.events && <EventList items={section.events} /> }
                        </ArticleSection>
                    )
                }

                if (sectionType === "program") {
                    return (
                        <ArticleSection {...section} subtitle={title} title={false} key={index}>
                            { program && program.items && <ProgramList items={program.items} /> }
                        </ArticleSection>
                    )
                }

                return (
                    <ArticleSection {...section} subtitle={title} title={false} key={index} />
                )

            })}
        </>
    )

}

export default CheckoutSections

