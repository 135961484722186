import React from "react";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {utils} from "@rjsf/core";
import {useTranslation} from "react-i18next";

const { schemaRequiresTrueValue } = utils;

const CheckboxWidget = (props) => {
    const {
        schema,
        id,
        value,
        disabled,
        readonly,
        //    label,
        autofocus,
        onChange,
        onBlur,
        onFocus,
        options
    } = props;

    // Because an unchecked checkbox will cause html5 validation to fail, only add
    // the "required" attribute if the field value must be "true", due to the
    // "const" or "enum" keywords
    const required = schemaRequiresTrueValue(schema);
    const { t } = useTranslation('portal');
    const _onChange = ({}, checked) => onChange(checked);
    const _onBlur = ({ target: { value } }) => onBlur(id, value);
    const _onFocus = ({ target: { value } }) => onFocus(id, value);

    const label = options.title || props.title || props.label || "bool"

    return (
        <>
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        id={id}
                        checked={typeof value === "undefined" ? false : value}
                        required={required}
                        disabled={disabled || readonly}
                        autoFocus={autofocus}
                        onChange={_onChange}
                        onBlur={_onBlur}
                        onFocus={_onFocus}
                    />
                }
                label={schema.url?
                    <span>{label}<a href={schema.url} target="_blank"> {t('terms')}</a></span> :
                    <span>{label}</span>}/>
        </>
    )
}

export default CheckboxWidget;
