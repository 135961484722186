import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ArticleBase,
  ArticleBody,
  ArticleContent,
  ArticleGrid,
} from '../components/article';
import Header from '../components/header/Header';
import HeaderExpanded from '../components/header/HeaderExpanded';
import Footer from '../components/footer/Footer';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  pageWrapper: {
    width: '100%',
    backgroundColor: 'white',
    display: 'block',
  },
});

const PageNotFoundView = ({ error }) => {
  const classes = useStyles();
  const [pageTitle, setPageTitle] = useState('');
  const { t } = useTranslation('portal');
  const logoText = t('logoText')
  const backgroundColor = '#b03b3b';
  const expanded = true;
  const description = '';
  const imageUrl = '';
  const orderId = '';

  useEffect(() => {
    if (error) {
      if (error.response?.data === 'Product is no longer available for sale') {
        setPageTitle(t('noLongerForSale'));
      } else if (error.response?.data === 'Product is not for sale yet') {
        setPageTitle(t('noYetForSale'));
      } else if (error.response?.status) {
        setPageTitle(t('productDoesNotExist'));
      } else {
        setPageTitle(t('pageDoesNotExist'));
      }
    }
  }, [error]);

  return (
    <div className={classes.pageWrapper}>
      <ArticleBase id="top">
        <Header
          logoText={logoText}
          backgroundColor={backgroundColor}
          expanded={expanded}
        >
          <HeaderExpanded
            imageUrl={imageUrl}
            title={pageTitle}
            description={description}
            orderId={orderId}
          />
        </Header>
        <ArticleGrid>
          <ArticleBody>
            <ArticleContent></ArticleContent>
          </ArticleBody>
        </ArticleGrid>
        <Footer />
      </ArticleBase>
    </div>
  );
};

export default PageNotFoundView;
