import React from "react"
import {makeStyles} from '@material-ui/core/styles';
import TicketListItem from "./TicketListItem"

const useStyles = makeStyles(theme => ({
    list: {
        display: "flex",
        flexDirection: "column",
        border: "1px solid",
        [theme.breakpoints.down('xs')]: {
            border: "none",
            marginLeft: theme.spacing(-1.5),
            marginRight: theme.spacing(-1.5)
        },
        "& > * + *": {
            borderTop: "1px solid",
            borderColor: theme.palette.divider,
        }
    },
    errorMessageArea: {
        backgroundColor:'yellow',
    },
}));

const TicketList = ({items, discounts, onChange, moreTicketsAvailable, mostRecentErrorMessage}) => {
    const classes = useStyles()
    return (
        <>
            <div className={classes.list}>
                {items.map((item, index) => (
                    <TicketListItem {...item} onChange={onChange} key={index} moreTicketsAvailable={moreTicketsAvailable} excludedFromCapacityCalculation={item.excludedFromCapacityCalculation}>

                    </TicketListItem>
                ))}
            </div>
            <div className={classes.errorMessageArea}>
                {mostRecentErrorMessage}
            </div>
        </>
    )
}


export default TicketList
