import React from 'react';
import moment from 'moment';
import "moment/locale/nb";
import "moment/locale/nn";
import "moment/locale/sv";

import { makeStyles } from '@material-ui/core/styles';

import ButtonBase from '@material-ui/core/ButtonBase';

const useStyles = makeStyles(theme => ({

    button: {
        display: "flex",
        width: "100%",

        justifyContent: "flex-start",
        alignItems: "flex-start",

        padding: theme.spacing(1),
        

        fontFamily: "Akkurat mono, sans-serif",
        fontSize: 14,
        lineHeight: 1,

        color: "black",

        "&[aria-selected=true]": {
            backgroundColor: "black",
            borderColor: "black",
            color: "white",

            "&[disabled]": {
                backgroundColor: "rgba(0,0,0,.5)",
                color: "white",
            }

        },

        "&[disabled]": {
            borderColor: "transparent",
            boxShadow: "none",
            color: theme.palette.action.disabled,


        },

        "&[data-status=full]": {
            borderColor: "rgba(0,0,0,.12)",
            backgroundImage: "repeating-linear-gradient(-45deg, rgba(0,0,0,.12), rgba(0,0,0,.12) 1px, transparent 1px, transparent 9px)",
        },

        "&:hover": {
            borderColor: "black",
        }

    }


}));


const CalendarDay = ({date, selectable, selected, disabled, status, isCurrentMonth, onClick}) => {
    const classes = useStyles()

    if (!isCurrentMonth) {
        return false
    }

    if (status === "full") {
        disabled = true
    }

    return (
        <ButtonBase className={classes.button}
            aria-selected={selected}
            data-status={status}
            disabled={!selectable || disabled}
            onClick={onClick}>
                {moment(date).format('D')}
        </ButtonBase>
    )
}

export default CalendarDay