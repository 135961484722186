import React from 'react';
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles';

import Calendar from "./Calendar"


const useStyles = makeStyles(theme => ({
    grid: {
        display: "flex",
        border: "1px solid"
    }


}));

const Datepicker = (props) => {

    const classes = useStyles()

    return (
        <div className={classes.grid}>
            <Calendar {...props} />
        </div>
    )

}

Datepicker.propTypes = {
    date: PropTypes.string,
    days: PropTypes.number,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    items: PropTypes.array,
    onSelect: PropTypes.func
}

export default Datepicker;
