import React from "react"
import ReactMarkdown from 'react-markdown'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    section: {
        display: "flex",
        flexDirection: "column",

        fontSize: 16,

        [theme.breakpoints.up('sm')]: {
        },        
        [theme.breakpoints.up('md')]: {
            fontSize: 18
        },        
        [theme.breakpoints.up('lg')]: {
            fontSize: 18
        },        
        [theme.breakpoints.up('xl')]: {
            fontSize: 20
        },        


        maxWidth: "100%",

        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),

        "& $section": {
            paddingLeft: 0,
            paddingRight: 0
        },

        [theme.breakpoints.up('md')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },

        fontFamily: "Akkurat, sans-serif",
//        fontSize: "1em",
        lineHeight: 1.5,

        margin: 0,

        "& > *": {
            marginTop: ".5em",
            marginBottom: ".5em",
        },

        "& > ol": {
            marginTop: "1em",
            marginBottom: "1em",

            "& li + li": {
                marginTop: ".5em"
            }
        },

        "& > ul": {
            marginTop: "1em",
            marginBottom: "1em",

            "& li + li": {
                marginTop: ".5em"
            }
        },
        

        "& > h2": {
            fontWeight: "normal",
            fontSize: "1.5em",
            marginTop: "1em",
            marginBottom: 0,
        },

        "& > h3": {
            fontSize: "1em",
            fontWeight: "bold",
            marginTop: "1em",
            marginBottom: ".5em",

            "& + p": {
                marginTop: "-.5em"
            }

        }

    },
        

}));


const ArticleSection = (props) => {

    let {
        title,
        subtitle,
        description,
        bodytext,
        body,
        children
    } = props

    if (!bodytext && body) {
        bodytext = body
    }

    const classes = useStyles()

    return (
        <section className={classes.section}>
            { title && <ReactMarkdown>{"## " + title + "\n"}</ReactMarkdown> }
            { subtitle && <ReactMarkdown>{"### " + subtitle + "\n"}</ReactMarkdown> }
            { description && <ReactMarkdown>{description}</ReactMarkdown> }
            { children }
            { bodytext && <ReactMarkdown>{bodytext}</ReactMarkdown> }
        </section>
    )
}


export default ArticleSection