import CommentWidget from "./CommentWidget";
import DiscountCodeWidget from "./DiscountCodeWidget";

export const generateUiSchema = (
    title= "Registrering",
    description= "Vi sender billettene til oppgitt e-postadresse.",
    newsletterOptionEnabled = false,
    registrationFormFreetextField = "",
    question = "",
    multipleChoice = false,
    backgroundColor,
    hideDiscountCodeField,
    rubicIntegrationEnabled = false,
    isGiftProduct = false,
    prefilledID = false,
    t
) => {
    return {
        "registration": {
            "ui:title": title,
            "ui:description": description,

            "firstName": {
                "ui:title": t('firstName')
            },
            "lastName": {
                "ui:title": t('lastName')
            },
            "email": {
                "ui:title": t('eMail')
            },
            "repeatEmail": {
                "ui:title": t('repeatEMail')
            },
            "phone": {
                "ui:title": t('phone')
            },
            "giftedToFirstName": {
                "ui:title": t('firstNameGiftReceiver'),
                "ui:widget": isGiftProduct ? "TextWidget" : "hidden",
            },
            "giftedToLastName": {
                "ui:title": t('lastNameGiftReceiver'),
                "ui:widget": isGiftProduct ? "TextWidget" : "hidden",
            },
            "rubicMemberId": {
                "ui:title": t('rebicMembershipNumber'),
                "ui:widget": rubicIntegrationEnabled ? "TextWidget" : "hidden",
                "ui:disabled": prefilledID,
                "ui:options": {
                    "help": t('rebicMembershipNumberHelp'),
                }
            },
            "address": {
                "ui:title": t('address'),
                "ui:widget": rubicIntegrationEnabled ? "TextWidget" : "hidden",
                "ui:options": {
                    "help": t('addressHelp'),
                }
            },
            "postalCode": {
                "ui:title": t('zipCode'),
                "ui:widget": rubicIntegrationEnabled ? "TextWidget" : "hidden",
            },
            "city": {
                "ui:title": t('city'),
                "ui:widget": rubicIntegrationEnabled ? "TextWidget" : "hidden",
            },
            "paymentType": {
                "ui:field": "Selector",
            },
            "question": {
                "ui:widget": question && question !== "" ? "checkboxes" : "hidden",
                "inline": true,
                "ui:options": {
                    "multipleChoice": multipleChoice,
                    "color": backgroundColor
                }
            },
            "commentField": {
                "ui:title": registrationFormFreetextField,
                "ui:widget": registrationFormFreetextField?.length > 0 ? CommentWidget : "hidden",
                "ui:options": {
                    "rows": 2,
                    "maxLength": 250
                }
            },
            "discountCode": {
                "ui:title": t('discountCode'),
                "ui:widget": hideDiscountCodeField ? "hidden" : DiscountCodeWidget
            },
        },
        "newsletter": {
            "ui:title": t('newsLetterCheckbox'),
            "ui:widget": newsletterOptionEnabled ? "checkbox" : "hidden",
        },
        "accept": {
            "ui:title": t('acceptTerms'),
        }
    }
}

export const generateJsonSchema = (paymentTypes,
                                   cartTotal,
                                   saleAgreementUrl,
                                   question,
                                   alternatives,
                                   mandatoryQuestion,
                                   orderId,
                                   applyDiscountCode,
                                   rubicIntegrationEnabled) => {
    const baseSchema = {
        "type": "object",
        "properties": {
            "registration": {
                "type": "object",
                "properties": {
                    "firstName": {
                        "type": "string"
                    },
                    "lastName": {
                        "type": "string"
                    },
                    "email": {
                        "type": "string",
                        "format": "email"
                    },
                    "repeatEmail": {
                        "type": "string",
                        "format": "email",
                        "repeatField": true,
                    },
                    "phone": {
                        "type": "string",
                    },
                    "giftedToFirstName": {
                        "type": "string",
                    },
                    "giftedToLastName": {
                        "type": "string",
                    },
                    "rubicMemberId": {
                      "type": "string",
                    },
                    "address": {
                        "type": "string",
                    },
                    "postalCode": {
                        "type": "string",
                    },
                    "city": {
                        "type": "string",
                    },
                    "paymentType": {
                        "type": "object",
                        "cartTotal": cartTotal,
                        "payTypes": paymentTypes || []
                    },
                    "commentField": {
                        "type": "string",
                    },
                    "discountCode": {
                        "type": "string",
                        "orderId": orderId,
                        "applyDiscountCode": applyDiscountCode
                    },
                },
                "required": getRequiredFields(mandatoryQuestion, question, rubicIntegrationEnabled),
            },
            "newsletter": {
                "type": "boolean",
            },
        },
    };

    if (question && question !== "") {
        baseSchema.properties.registration.properties["question"] = {
        "type": "array",
        "title": question,
        "items": {
            "type": "string",
            "enum": alternatives
        },
        "uniqueItems": true
    }}

    if (saleAgreementUrl && saleAgreementUrl !== "") {
        baseSchema.properties["accept"] =  {
            "type": "boolean",
            "url": saleAgreementUrl
        }

        baseSchema["required"] = ["accept"]
    }

    return baseSchema
}

const getRequiredFields = (isMandatoryQuestion, question, rubicIntegrationEnabled) => {
    let fields = ["firstName", "lastName", "email", "repeatEmail", "phone"]
    if (isMandatoryQuestion && question && question !== "") {
        fields.push("question")
    }

    if (rubicIntegrationEnabled) {
        fields.push("address", "city", "postalCode")
    }

    return fields
}