import React from "react"

const CustomFieldTemplate = ({props, children}) => {
//  const {id, classNames, label, help, required, description, errors, children} = props;
  return (
    <div>
      {children}
    </div>
  );
}

export default CustomFieldTemplate