import React from "react"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import ToggleIcon from '@material-ui/icons/ArrowDownward';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    toggle: {
        width: "100%",
        backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, .5) 40%, rgba(255, 255, 255, 0) 20%)",
        backgroundPosition: "top",
        backgroundSize: "3px 1px",
        backgroundRepeat: "repeat-x",
        padding: theme.spacing(1),
        justifyContent: "flex-start",
        [theme.breakpoints.up('md')]: {
            display: "none",
        }
    },
    icon: {
        width: 18,
        transition: ".125s ease-in-out",
        transform: "rotate(0deg)",
        "&[aria-expanded=true]": {
            transition: ".125s ease-in-out",
            transform: "rotate(180deg)"
        }
    },
    title: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: 14,
        lineHeight: 1.5,
        margin: theme.spacing(0, 1)
    }
}));

const CartToggle = ({tickets = {}, expanded, onClick}) => {
    const { t } = useTranslation('portal')
    const classes = useStyles()

    let label = t('cartShowDetails');
    let count = tickets && (tickets.count || 0)

    if (count >= 2) {
        label = count + " billetter"
    } else if (count === 1) {
        label = count + " billett"
    }

    return (
        <ButtonBase className={classes.toggle} onClick={onClick}>
            <ToggleIcon className={classes.icon} aria-expanded={expanded} />
            <Typography className={classes.title}>
                { expanded ? t('cartHideDetails') : label }
            </Typography>
        </ButtonBase>
    )

}


export default CartToggle