import React, {useEffect, useState} from "react"
import CheckoutBase from "../CheckoutBase";
import RegistrationForm from "../registration/RegistrationForm";
import {bpnAPI, useAxiosBpn} from "../../app/axios";
import {useHistory} from "react-router-dom";
import {styled} from "@material-ui/core";


const ErrorMessageContainer = styled('div')(({ theme }) => ({
    backgroundColor: 'yellow',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
}));

const CheckoutRegister = (props) => {
    const { cart, order, orderLines, payTypes, draft, museumInfo } = props;
    const history = useHistory()
    const [errorMessage, setErrorMessage] = useState('');
    const queryParams = new URLSearchParams(window.location.search)
    const locale = queryParams.get("lng")
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
    const [formData, setFormData] = useState({
        total: cart?.total ? cart.total : 0,
        registration: {rubicMemberId: props.rubicInfo.memberId}
    })
    const [errors, setErrors] = useState([])
    const [{loading, error: orderProcessError}, processOrder] = useAxiosBpn({
        url: "orders/process",
        params: {orderId: order.externalId, locale: locale},
        method: "PUT"
    }, {manual: true})

    const _onChange = ({formData, errors, ...props}) => {
        setFormData(formData)
        setErrors(errors)
    }

    useEffect(() => setFormData(prevState => ({...prevState, total: cart.total})), [cart.total])

    const _onSubmit = ({formData, errors, ...props}) => {
        setSubmitBtnDisabled(true)
        setErrors(errors)

        if (formData.newsletter) {
            bpnAPI.put("subscribe/" + museumInfo?.id, {
                firstName: formData.registration.firstName,
                lastName: formData.registration.lastName,
                email: formData.registration.email,
            }).then(response => {
                console.log(response)
            })
        }


        if (cart){
            const formQuestionResult = {question: props.schema.properties?.registration?.properties?.question?.title, answers: formData.registration?.question}
            bpnAPI.put("orders/" + order.id, {
                    customer: {
                        firstName: formData.registration.firstName,
                        lastName: formData.registration.lastName,
                        email: formData.registration.email,
                        phone: formData.registration.phone,
                        rubicMemberId: formData.registration.rubicMemberId,
                    },
                    address: {
                        line1: formData.registration.address,
                        postalCode: formData.registration.postalCode,
                        city: formData.registration.city
                    },
                    paymentTypeId: formData.registration.paymentType?.id || payTypes[0]?.id || null,
                    orderLines: orderLines,
                    discountCode: order.discountCode,
                    giftedToFirstName: formData.registration.giftedToFirstName,
                    giftedToLastName: formData.registration.giftedToLastName,
                    comment: formData.registration.commentField,
                    questionAnswers: formQuestionResult ? JSON.stringify(formQuestionResult) : null,
                },
                {
                    params: {
                        register: true,
                        locale: locale
                    }
                }).then(response => {
                if (response.data.orderLinesStatus.status === 'NOT OK') {
                    setErrorMessage(response.data.orderLinesStatus.text)
                    setSubmitBtnDisabled(false)
                } else {
                    setErrorMessage('')
                    if (cart.total <= 0) {
                        processOrder().then(({data: {order}}) => {
                            if (order.orderLines && order.orderLines.length) {
                                const {externalId} = order
                                history.push(`/receipt/${externalId}`)
                            }
                        })
                    } else {
                        //window.open(response.data.redirectUrl, '_blank');
                        window.location.replace(response.data.redirectUrl)
                    }
                }
            })
        }
    }

    return (
        <CheckoutBase {...props} expanded={false}>
            <RegistrationForm {...props}
                              formData={formData}
                              disabledSubmit={submitBtnDisabled}
                              errors={errors}
                              onChange={_onChange}
                              onSubmit={_onSubmit} />
            {errorMessage !== '' && <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>}
        </CheckoutBase>
    )
}

export default CheckoutRegister