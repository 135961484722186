import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {museumNs, museumTranslationKey} from "../utils/staging";

const useStyles = makeStyles(theme => ({
    orderLine: {
        paddingLeft: '20px',
        paddingRight: '20px',
        fontSize: '1rem',
        display: 'flex',
        justifyContent: 'space-between',
    },
    priceWrapper: {
        width:'50%',
        display:'flex',
        justifyContent: 'space-between',
    },
    productType: {
        width:'50%',
        overflowWrap: 'break-word',
    },
    divider: {
        borderTop: 'dotted',
        borderBottom: 0,
        borderWidth: '2px',
        color:'grey',
    }
}));


const OrderLine = ({orderLine}) => {
    const classes = useStyles()
    const { t } = useTranslation(museumNs(orderLine.museumId))
    const parentKey = museumTranslationKey({
        id: orderLine.productId,
        type: 'product'
    })
    const getFullPriceForOrderLine = (orderline) => {
        let price = 0
        if (orderline.originalPriceIfDiscount) {
            price = orderLine.originalPriceIfDiscount*orderLine.quantity
        } else {
            price =  orderLine.price*orderLine.quantity
        }

        return price.toLocaleString('de-DE', {minimumFractionDigits: 2})
    }

    return (
        <>
            <div className={classes.orderLine}>
                <div className={classes.productType}>{orderLine.quantity} × {t(`${parentKey}.tickets.${orderLine?.productPriceCategoryName?.toLowerCase()}`, orderLine.productPriceCategoryName)}</div>
                <div className={classes.priceWrapper}>mva {orderLine.vat}%
                    <div>{getFullPriceForOrderLine(orderLine)}</div>
                </div>
            </div>
            <hr className={classes.divider}/>
        </>
    )
}

export default OrderLine