import React, {useState} from "react";

import TextField from "@material-ui/core/TextField";

import {utils} from "@rjsf/core";
import {makeStyles} from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import {bpnAPI} from "../../app/axios";
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import {Accordion, AccordionDetails, AccordionSummary, Zoom} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const { getDisplayLabel } = utils;


const useStyles = makeStyles(theme => ({
    discountCodeInputWrapper: {
        display: 'flex',
    },
    button: {
        border: "1px solid",
        display: "flex",
        width: "40%",
        padding: theme.spacing(1),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        fontFamily: "Akkurat, sans-serif",
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "22px",
        color: props => { return props.disabledButton ? "grey" : "black" },
        borderColor: props => { return props.disabledButton ? "grey" : "black" },
        "&:hover": {
            backgroundColor: "black",
            borderColor: "black",
            color: "white"
        },
    },
    buttonContainer: {
        width: "100%",
        alignSelf: "center"
    },
    accordion: {
      padding: 0,
        width: '100%'
    },
    validationIcon: {
        marginLeft: theme.spacing(1),
        color: props => { return props.validDiscountCode ? "green" : "#ef5050" },
    }
}));


const DiscountCodeWidget = ({
                                id,
                                required,
                                readonly,
                                disabled,
                                type,
//    label,
                                value,
                                onChange,
                                onBlur,
                                onFocus,
                                autofocus,
                                options,
                                schema,
                                uiSchema,
                                rawErrors = [],
                                formContext,
                                ...props
                            }) => {
    const isButtonDisabled = () => {
        return disabled || !schema.orderId || !value || ongoingValidation
    }

    const { t } = useTranslation('portal');
    const [validDiscountCode, setValidDiscountCode] = useState()
    const [showDiscountCode, setShowDiscountCode] = useState(false)
    const [ongoingValidation, setOngoingValidation] = useState(false)
    const classes = useStyles({validDiscountCode: validDiscountCode, disabledButton: isButtonDisabled()})
    const orderId = schema.orderId
    const _onChange = (event) => onChange(event.target.value === "" ? options.emptyValue : event.target.value.toUpperCase());
    const _onBlur = (event) => {
        onBlur(id, event.target.value?.toUpperCase());
        onChange(event.target.value.trim().toUpperCase())
    }


    const _onFocus = (event) => onFocus(id, event.target.value);

    const displayLabel = getDisplayLabel(
        schema,
        uiSchema
    );

    const label = options.title || props.title || props.label || schema.title
    const variant = options.variant || "filled"
    const size = options.size
    const helperText = options.help || null

    const handlePaste = (e) => {
        if (schema.repeatField) {
            e.preventDefault();
            value = "";
        }
    };


    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const preventSpam = async () => {
        await sleep(200);
    }

    const applyDiscountCode = async () => {
        setOngoingValidation(true)
        await preventSpam(2000);
        bpnAPI.put("orders/discount_code/" + value, {orderId: orderId }).then(response => {
            setValidDiscountCode(true)
            setOngoingValidation(false)
            schema.applyDiscountCode(response, value)
        }).catch(() => {
            setOngoingValidation(false)
            setValidDiscountCode(false)
        })
    }

    return (
        <div className={classes.discountCodeInputWrapper}>
            <Accordion elevation={0} className={classes.accordion} expanded={showDiscountCode} onChange={() => setShowDiscountCode(!showDiscountCode)}>
                <AccordionSummary className={classes.accordion}>
                    <>
                    {t('doYouHaveDiscountCode') + '   '}
                    {showDiscountCode ? <ExpandLessIcon style={{color: 'grey', marginLeft: '3%'}}/> : <ExpandMoreIcon style={{color: 'grey', marginLeft: '3%'}}/> }
                </>
                </AccordionSummary>
                <AccordionDetails className={classes.accordion}>
                    <TextField
                        {...props}
                        id={id}
                        variant={"standard"}
                        size={size}
                        label={displayLabel && label}
                        helperText={helperText}
                        autoFocus={autofocus}
                        required={required}
                        disabled={disabled || readonly}
                        onPaste={handlePaste}
                        type={type || schema.type}
                        value={value || value === 0 ? value : ""}
                        error={rawErrors.length > 0}
                        onChange={_onChange}
                        onBlur={_onBlur}
                        onFocus={_onFocus}
                        InputProps={{
                        }}
                    />
                    <div className={classes.buttonContainer}>
                        <ButtonBase  type={type} data-variant={variant} disabled={isButtonDisabled()} className={classes.button} onClick={() => applyDiscountCode()}>
                            {t('add', 'Legg til')}
                            {validDiscountCode && <Zoom in={validDiscountCode}><CheckIcon className={classes.validationIcon}/></Zoom>}
                            {validDiscountCode === false && <Zoom in={!validDiscountCode}><ErrorIcon className={classes.validationIcon}/></Zoom>}
                        </ButtonBase>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>

    );
};

export default DiscountCodeWidget;