import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import {ArticleGrid} from "../article";
import Color from 'color';
import Logo from "../logo/Logo";
import {useTranslation} from 'react-i18next'

const getContrast = (color) => {

    color = Color(color);

    if (color.isDark()) {
        return "white"
    } else {
        return "black"
    }

}

const useStyles = makeStyles(theme => ({
    footer: {
        display: "block",

        backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, .5) 40%, rgba(255, 255, 255, 0) 20%)",
        backgroundPosition: "top",
        backgroundSize: "3px 1px",
        backgroundRepeat: "repeat-x",

        padding: theme.spacing(2),

        marginBottom: theme.spacing(14),

        [theme.breakpoints.up('md')]: {
            marginBottom: 0,
        },

    },
    logo: {
        position: "relative",
        fontSize: 16,
        margin: "0.8em",

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",

        [theme.breakpoints.up('md')]: {
            fontSize: 18,
            margin: "0.8em 0",
        },

        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
            margin: "0.8em 0",
        },
    },
    info: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: 14,
        textAlign: "center",
        color: theme.palette.text.secondary,

        "& > *": {
            margin: 0
        },

        "& > * + *": {
            marginTop: "1em"
        },

        "& a": {
            color: "black"
        }


    }
}));

const Footer = ({backgroundColor = "#eee", logoText = "Museumsbillett", date, steps, currentStep, onSelect, ...props}) => {

    const color = backgroundColor && getContrast(backgroundColor)
    const classes = useStyles({backgroundColor, color})
    const { t, ready } = useTranslation('portal')


    return (
        <footer className={classes.footer} aria-expanded={false}>
            {ready && <ArticleGrid>
                <Logo className={classes.logo} variant="portrait" logoText={logoText} />
                <section className={classes.info}>
                    <p>{t('footerPaymentNets')} </p>
                    <p>{t('footerContactInformationText')}</p>
                    <p><a href="https://museumsit.no/personvern">{t('footerPrivacyNotice')}</a></p>
                    <p>&copy; KulturIT </p>
                </section>
            </ArticleGrid>}
        </footer>
    )

}

export default Footer