import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {orderStatus} from "../../views/ReceiptView";
import ReceiptLine from "./ReceiptLine";
import OrderLine from "./OrderLine";
import moment from "moment";
import VatSection from "./VatSection";
import {useTranslation} from "react-i18next";
import PdfPasses from "./PdfPasses";
import {PDFDownloadLink} from "@react-pdf/renderer";
import ButtonBase from "@material-ui/core/ButtonBase";


const useStyles = makeStyles(theme => ({
    receipt: {
        backgroundColor: "white",
        boxShadow: theme.shadows[2],
        margin: theme.spacing(1.5, 0)
    },
    footer: {
        padding: theme.spacing(1.5),
        fontFamily: "Akkurat, sans-serif",
        fontSize: 16,
    },
    header: {
        padding: theme.spacing(1.5),
        fontFamily: "Akkurat, sans-serif",
        fontSize: 14,
        lineHeight: 1.5
    },
    row: {
        display: "flex"
    },
    title: {
        fontSize: 24,
        fontWeight: "normal"
    },
    subTitle: {
        padding: theme.spacing(1.5),
        fontFamily: "Akkurat, sans-serif",
        fontSize: 18,
        fontWeight: "bold"
    },
    orderTotal: {
        display:'flex',
        padding: theme.spacing(1.5),
        fontFamily: "Akkurat, sans-serif",
        fontSize: 18,
        fontWeight: "bold",
        justifyContent: 'space-between',
    },
    orderDiscount: {
        display:'flex',
        padding: theme.spacing(1.5),
        fontFamily: "Akkurat, sans-serif",
        fontSize: '1rem',
        justifyContent: 'space-between',
    },
    yellowBackground: {
        backgroundColor: 'yellow',
    },
    receiptImage: {
        maxWidth: "100px",
        maxHeight: "100px",
        float: "right"
    },
    address: {
        fontSize: 14,
        lineHeight: 1.5,
        fontStyle: "normal",
        textAlign: "center",

        "& > strong": {
            fontSize: 16,
            fontWeight: "normal"
        }
    },
    downloadButtonContainer: {
        backgroundColor: "white",
        padding: theme.spacing(1.5),
        zIndex: 100,
        position: "relative",
        top: "auto",
        boxShadow: "none",
        left: 0,
        right: 0,
        bottom: 0,
        "& a": {
            textDecoration: "auto",
            display:"inline-block",
            width:"100%",
            height:"100%",
            color: "black",
            flexGrow: 1,

            "&:hover": {
                color: "white",
            }
        },
    },
    downloadButton: {
        border: "1px solid",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: theme.spacing(4),
        color: "black",
        borderColor: "black",
        fontFamily: "Akkurat, sans-serif",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "bold",
        borderWidth: "2px",

        "&:hover": {
            backgroundColor: "black",
            borderColor: "black",
            color: "white"
        },
    }


}));


const Receipt = ({order, product, imageUrl}) => {
    const classes = useStyles()
    const { t } = useTranslation('portal');
    const pdfDocument = (<PdfPasses passes={order.tickets} order={order} test={order.test} product={product} imageUrl={imageUrl}/>);


    const getOrderTotal = (order) => {
        let orderTotal = 0;
        order.orderLines.forEach(orderLine => {
            orderTotal+= orderLine.price*orderLine.quantity;
        })

        return orderTotal;
    }

    const getTotalDiscount = (order) => {
        let totalDiscount = 0
        order.orderLines.forEach(orderLine => {
            if (orderLine.originalPriceIfDiscount)
                totalDiscount+= (orderLine.originalPriceIfDiscount*orderLine.quantity) - (orderLine.price*orderLine.quantity);
        })

        return totalDiscount;
    }

    const getTotalVat = (orderLines) => {
        let totalVat = 0;
        orderLines.forEach(orderLine => {
            totalVat+= ((orderLine.price*orderLine.quantity)/(1+(orderLine.vat)/100));
        })

        return totalVat;
    }

    const orderTotal = getOrderTotal(order)
    const totalDiscount = getTotalDiscount(order)


    const getDiscountPercentage = () => {
        return Math.round((totalDiscount / (orderTotal+totalDiscount) * 100))
    }



    return (
        <div className={classes.receipt}>
            <header className={classes.header}>
                {imageUrl && imageUrl !== "" && <img src={imageUrl} className={classes.receiptImage} alt={"receiptImage"}/>}
                <h2 className={classes.title}>{order.status === orderStatus.CONFIRMED_CREDITED ?
                    <div className={classes.yellowBackground}>{t("creditedReceipt")} </div> : <div>{t("receipt")}</div>}</h2>
                <div className={classes.details}>
                    <div className={classes.name}>{t("receipt") + " " + order.externalId}</div>
                    <div className={classes.date}>{moment(order.createdAt).format("LLL")}</div>
                </div>
            </header>

            <h2 className={classes.subTitle}>{product?.type === 'seasonal_pass' ? t("products") : t("tickets")}</h2>
            {order.orderLines.map(orderLine => {
                return <OrderLine  key={orderLine.id} orderLine={orderLine}/>
            })}
            { totalDiscount > 0 &&
            <div className={classes.orderDiscount}>

                <>
                    <div>{t('discount')} (-{getDiscountPercentage()}%)</div>
                    <div>
                        {totalDiscount.toLocaleString('de-DE', {minimumFractionDigits: 2})}
                    </div>
                </>
            </div>}
            <div className={classes.orderTotal}>
                <div>{t("payed")}</div>
                <div>
                    {orderTotal.toLocaleString('de-DE', {minimumFractionDigits: 2})}
                </div>
            </div>
            <VatSection order={order}/>
            <ReceiptLine key={'mva'} description={t("totalVat")} value={(orderTotal-getTotalVat(order.orderLines)).toLocaleString('de-DE', {minimumFractionDigits: 2})}/>
            <ReceiptLine key={'eksMva'} description={t("exlVat")} value={getTotalVat(order.orderLines).toLocaleString('de-DE', {minimumFractionDigits: 2})}/>
            <ReceiptLine key={'total'} description={t("payedWithNetaxept")} value={orderTotal.toLocaleString('de-DE', {minimumFractionDigits: 2})}/>
            <footer className={classes.footer}>
                <address className={classes.address}>
                    <strong>{product.museumSettings?.screenName || order.orderLines[0].museumName}</strong><br />
                    {product.museumSettings?.street && <strong>{product.museumSettings.street}<br /></strong>}
                    {product.museumSettings?.zipCode && <strong>{product.museumSettings.zipCode} {product.museumSettings.city}<br /><br /></strong>}
                    {product.museumSettings?.orgNr && <strong>Org.nr: {product.museumSettings.orgNr}<br /></strong>}
                </address>
                <div className={classes.downloadButtonContainer}>
                    <PDFDownloadLink document={pdfDocument} fileName={`ordrebekreftelse-${order.externalId}`}>
                        <ButtonBase className={classes.downloadButton} onClick={() => console.log("Downloading ticket with external id " + order.externalId + "...")}>
                            {({ blob, url, loading, error }) => (loading ? t("generatingPdf") : t("downloadReceipt"))}
                            {t("downloadReceipt")}
                        </ButtonBase>
                    </PDFDownloadLink>
                </div>
            </footer>
        </div>
    )
}

export default Receipt
