import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import QRCode from "qrcode.react";
import TicketStatusSticker from "./TicketStatusSticker";
import IsTestSticker from "./IsTestSticker"
import {bpnAPI} from "../../app/axios";
import {getValidDateTimeForEvent} from "./utils";
import ValidateTicketDialog from "./ValidateTicketDialog";
import LargeQrCodeDialog from "./LargeQrCodeDialog";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {museumNs, museumTranslationKey} from "../utils/staging";
import AddToWallet from "../../assets/Add_to_Apple_Wallet_rgb_NO.svg";
import {getTranslationByLocale} from "../utils/getCheckoutFromApiData";
import axios from "axios";

export const ValidationAttemptStatus = {
    VALIDATED: 'validated',
    ALREADY_USED: 'already_used',
    EVENT_NOT_STARTED: 'event_not_started',
    EVENT_PASSED: 'event_not_started',
    IN_TIMEFRAME: 'event_not_started',
    CREDITED: 'credited',
}

const useStyles = makeStyles(theme => ({
    ticket: {
        display: 'grid',
        width: '200px',
        borderRadius: '5%',
        position: 'relative',
        fontFamily: 'Akkurat, sans-serif',
        fontSize: '16px',
        gridTemplateColumns: 'auto auto',
        justifyContent: 'stretch',
        placeItems: 'start',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        gridTemplateAreas: `
        "code code"
        "location location"
        "time time"
        "eventTitle eventTitle"
        "category price"
        "qrCode qrCode"
        "validateButton validateButton"
        "addToWalletImage addToWalletImage"
        `,
    },
    location: {
        gridArea: 'location',
        fontWeight: 'bold',
        marginTop:theme.spacing(1),
    },
    time: {
        gridArea: 'time',
        marginBottom:theme.spacing(2),
    },
    addToWalletImage: {
        width: 150,
        cursor: "pointer",
        justifySelf: "center",
        marginTop: theme.spacing(2),
        gridArea: 'addToWalletImage',
    },
    code: {
        gridArea: 'code',
        alignSelf: 'start',
        justifySelf: 'center',
        color:'grey',
        fontSize: '14px',
    },
    eventTitle: {
        gridArea: 'eventTitle',
        fontSize: '25px',
        marginBottom:theme.spacing(2),
        overflowWrap: 'anywhere',
    },
    mainTicketCode: {
        gridArea: 'eventTitle',
        fontSize: '30px',
        justifySelf: 'center',
    },
    category: {
        gridArea: 'category',
    },
    price: {
        gridArea: 'price',
    },
    validateButton: {
        gridArea: 'validateButton',
        justifySelf: 'center',
        fontFamily: 'Akkurat, sans-serif',
        fontSize: '16px',
        lineHeight: '24px',
    },
    qrCode: {
        gridArea: 'qrCode',
        justifySelf: 'center',
        marginBottom:theme.spacing(1),
        marginTop:theme.spacing(1),
    },
    "& .MuiTypography-root": {
        fontFamily: 'Akkurat, sans-serif',
    },
    statusText: {
        fontSize: '2rem',
        position: 'absolute',
        top: '50%',
        left: '50%',
        padding: theme.spacing(2),
        borderStyle: 'dotted',
        transform: 'translate(-50%, -50%) rotate(45deg)',
        backgroundColor: '#ff2345',
    },
}));



const BoughtTicket = ({ isMainTicket = false, mainTicketValidationResult = [], ticket, orderLine, onMainTicketValidated = null, productReceiptData = null, order}) => {
    const {museumName, museumId, productPriceCategoryName, price, originalPriceIfDiscount, test, productId} = orderLine;
    const classes = useStyles()
    const [showLargeQrCode, setShowLargeQrCode] = useState(false);
    const { t } = useTranslation('portal')
    const [showConfirmValidate, setShowConfirmValidate] = useState(false);
    const [validationResult, setValidationResult] = useState();
    const locale = localStorage.getItem("i18nextLng")


    const parentKey = museumTranslationKey({
        id: productId,
        type: 'product'
    })


    const getPriceTranslationByPriceCategoryName = (prices, priceCategoryName, locale) => {
        const priceTranslations = prices.find(price => price.priceCategoryName === priceCategoryName)?.translations
        if (priceTranslations) {
            const translation = priceTranslations.find(translation => translation.languageLocale === locale.toLowerCase())
            return translation?.name
        }
        return priceCategoryName
    }

    const getPriceLabel = (price, originalPriceIfDiscount) => {
        if (price === 0) {
            return t('priceLabelFree')
        } else if (originalPriceIfDiscount) {
            return `${originalPriceIfDiscount},-`
        } else {
            return `${price},-`
        }
    }

    useEffect(() => {
        if (mainTicketValidationResult.length > 0) {
            const validationResult = mainTicketValidationResult.filter(validationAttempt => validationAttempt.ticketId === ticket.id)
            if (validationResult) {
                setValidationResult(validationResult[0])
            }
        }
    }, [mainTicketValidationResult, ticket.id])


    const handleClose = () => {
        setShowLargeQrCode(false);
        setShowConfirmValidate(false);
    };


    const validateTicket = e => {
        e.preventDefault();
        bpnAPI.put(`tickets/${ticket.code}/validate?validationType=customer`,
            {
                museumId: museumId,
                museumName: museumName,
            }).then(res => {
            const validationAttempts = res.data?.validationAttempts;
            setShowConfirmValidate(false);
            if (res.status === 200 && validationAttempts) {
                if (isMainTicket ) {
                    onMainTicketValidated(validationAttempts);
                } else {
                    setValidationResult(validationAttempts[0]);
                }
            } else {
                console.error('Validation request failed');
            }
        })
            .catch(e => {
                console.error(e);
            });
    };

    const getStickerTextAndColor = (status) => {
        let sticker = {text: '', color: ''}
        switch (status) {
            case ValidationAttemptStatus.VALIDATED:
                sticker.color = 'green'
                sticker.text = t("validationResponse.accepted")
                return sticker
            case ValidationAttemptStatus.EVENT_NOT_STARTED:
                sticker.color = 'yellow'
                sticker.text = t("validationResponse.notStarted")
                return sticker
            case ValidationAttemptStatus.EVENT_PASSED:
                sticker.color = 'yellow'
                sticker.text = t("validationResponse.eventDone")
                return sticker
            case ValidationAttemptStatus.CREDITED:
                sticker.color = 'yellow'
                sticker.text = t("validationResponse.credited")
                return sticker
            case ValidationAttemptStatus.ALREADY_USED:
                sticker.color = 'grey'
                sticker.text = t("validationResponse.used")
                return sticker
            default:
                sticker.color = 'red'
                sticker.text = t("validationResponse.notAccepted")
                return sticker
        }
    }

    const getValidPeriodSeasonalPass = (productReceiptData, order) => {
        const {validFrom, validTo, daysValid} = productReceiptData;
        let resultString = ''
        if (daysValid) {
            const createdAt = moment(order?.createdAt)
            createdAt.add(daysValid, "days")
            resultString = t("validTo") + " " + createdAt.format('DD.MM.YYYY')
        } else {
            const validFromFormatted = moment(validFrom).format('DD.MM.YYYY')
            const validToFormatted = moment(validTo).format('DD.MM.YYYY')
            resultString = t("validWithinPeriod") + " " + validFromFormatted + " - " + validToFormatted

        }

        return resultString
    }

    const getPassFile = () => {
        bpnAPI.get(`applepass/${ticket.code}`,
            {responseType: 'arraybuffer', headers: {
            "Access-Control-Allow-Origin": "*"
        }}).then((response) => {
            let pkPassBlob = new Blob([response.data])
            pkPassBlob = pkPassBlob.slice(0, pkPassBlob.size, "application/vnd.apple.pkpass")
            const url = window.URL.createObjectURL(pkPassBlob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${ticket.code}.pkpass`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }

    return (
        <Paper className={classes.ticket} elevation={3}>
            {validationResult && !isMainTicket &&
            <TicketStatusSticker
                backgroundColor={getStickerTextAndColor(validationResult?.status).color}
                statusText={getStickerTextAndColor(validationResult?.status).text}/>
            }

            {!isMainTicket ?
                <>
                    <span className={classes.location}>{museumName}</span>
                    {orderLine.productType !== "seasonal_pass" ?
                        <span className={classes.time}>{getValidDateTimeForEvent(orderLine)}</span> :
                        <span className={classes.time}>{getValidPeriodSeasonalPass(productReceiptData, order, t)}</span>}
                    <span className={classes.eventTitle}>{t(`${parentKey}.title`, getTranslationByLocale(productReceiptData,"name", localStorage.getItem("i18nextLng")))}</span>
                    <span className={classes.category}>{t(`${parentKey}.tickets.${productPriceCategoryName?.toLowerCase()}`, getPriceTranslationByPriceCategoryName(productReceiptData?.prices, productPriceCategoryName, locale) || productPriceCategoryName)}</span>
                    <span className={classes.price}>{getPriceLabel(price, originalPriceIfDiscount)}</span>
                    <span className={classes.code}>{ticket.code}</span>
                </> :
                <>
                    <span className={classes.mainTicketCode}>{t("mainTicket")}</span>
                    <span className={classes.location}>{museumName}</span>
                    <span className={classes.code}>{ticket.code}</span>
                </>
            }
            { test && <IsTestSticker statusText={t('testTicketInfoText')} /> }
            <Button variant={"outlined"} className={classes.validateButton}
                    onClick={() => setShowConfirmValidate(true)}
                    disabled={!!validationResult?.status}>{t("validateTicket")}</Button>
            {/*img used for enabling add to apple wallet*/}
            {/*{isMainTicket && <img src={AddToWallet} className={classes.addToWalletImage} alt="add to apple wallet" onClick={() => getPassFile()}/>}*/}
            <div className={classes.qrCode} onClick={() => setShowLargeQrCode(true)}>
                <QRCode className={classes.qrCode} value={ticket.code} renderAs="svg" size={128} />
            </div>
            <ValidateTicketDialog handleClose={handleClose} showConfirmValidate={showConfirmValidate} validateTicket={validateTicket}/>
            <LargeQrCodeDialog handleClose={handleClose} showLargeQrCode={showLargeQrCode} ticketCode={ticket.code}/>
        </Paper>
    )
}

export default BoughtTicket
