export const getPasses = ({pass = {}, tickets = {}, program = {}, summary = {}, cart = {}}) => {

//    const cartTickets = cart && cart.tickets && Object.values(cart.tickets.items)
    const cartAddons = cart && cart.addons && Object.values(cart.addons.items)

    let passes = []


    if (cart?.sections) {
        cart.sections.forEach(section => {

            const { type, items } = section

            if (type === "bookings" && items) {

                passes.push({
                    ...pass,
                    ...summary,
                    ...section,
                    title: section.title,
                    categoryLabel: "Antall",
                    category: items.length
                })

            } else if (type === "products" && items) {

                items.forEach(item => {

                    passes.push({
                        ...pass,
                        ...summary,
                        ...item,
                        title: item.title,
                        categoryLabel: "Antall",
                        category: items.length,
//                        price: item.description
                    })
                })

            } else if (items) {
                items.map(item => {

                    const { count } = item

                    if (type === "tickets") {
                        for (let i = 1; i <= count; i++) {
                            passes.push({
                                ...pass,
//                                ...item,
                                backgroundColor: item.backgroundColor || pass.backgroundColor,
                                category: item.title,
                                price: item.price,
                                title: summary.title,
                                location: summary.location,
                                date: summary.date,
                                time: summary.time
                            })
                        }
                    }
                })
            }
        })
    }

    return passes

}