import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import ProgramList from "./ProgramList"
import moment from "moment"

import ArticleSection from "../article/ArticleSection";

const useStyles = makeStyles(theme => ({
    preview: {
        display: "flex",
        flexDirection: "column",
        margin: theme.spacing(2, 0)
    },
    title: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: 24,
        lineHeight: 1.5,
        marginTop: 0,
        marginBottom: "1em",
    }
}));

const ProgramPreview = ({date, events = []}) => {

    const classes = useStyles()

    const title = "Hva skjer?"
    const description = "program for " +  moment(date).format("LL") + ":"
    return (
        <div></div>
        // <ArticleSection subtitle={title} description={description}>
        //     <ProgramList items={events} preview={true} />
        // </ArticleSection>
    )

}


export default ProgramPreview