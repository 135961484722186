import {Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    borderRadius: 0,
    borderWidth: '2px',
    fontFamily: "Akkurat, sans-serif",
    fontSize: 14,
  },
}));

export const ButtonSelector  = ({
                                  onClick,
                                  readOnly = false,
                                  isSelected = false,
                                  children,
                                }) => {
  const classes = useStyles({ readOnly, onClick, isSelected });
  return (
      <Button
          className={classes.button}
          variant={isSelected ? 'contained' : 'outlined'}
          onClick={onClick}>
        {children}
      </Button>
  );
};

export default ButtonSelector;
