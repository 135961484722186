import React, {Suspense} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import CheckoutView from "../views/CheckoutView";
import {NetaxeptCallback} from "./NetaxeptCallback";
import CustomSnackbar from "../components/CustomSnackbar";
import {SnackbarProvider} from "material-ui-snackbar-provider"
import ReceiptView from "../views/ReceiptView";
import PageNotFoundView from "../views/PageNotFoundView";
import CancelledOrderView from "../views/CancelledOrderView";
import FrontPageView from "../views/FrontPageView";
import {ProgressIndicator} from './ProgressIndicator';
import TicketStatusView from "../views/TicketStatusView"
import i18next from "./i18n";
import {I18nextProvider} from "react-i18next";

function App() {
    return (
        <I18nextProvider i18n={i18next}>
            <Suspense fallback={<ProgressIndicator/>}>
                <div className="App">
                    <Router>
                        <SnackbarProvider SnackbarProps={{autoHideDuration: 8000}}
                                          SnackbarComponent={CustomSnackbar}>
                            <NetaxeptCallback>
                                <Switch>
                                    <Route exact path={"/"}>
                                        <FrontPageView/>
                                    </Route>
                                    <Route exact path={"/:id"}>
                                        <CheckoutView draft={false}/>
                                    </Route>
                                    <Route exact path={"/draft/:id"}>
                                        <CheckoutView draft={true}/>
                                    </Route>
                                    <Route exact path={"/cancelled/:id"}>
                                        <CancelledOrderView/>
                                    </Route>
                                    <Route exact path={"/:productId/stats"}>
                                        <TicketStatusView/>
                                    </Route>
                                    <Route exact path={"/status/:parentMuseumId"}>
                                        <TicketStatusView/>
                                    </Route>
                                    <Route exact path={"/receipt/:externalId"}>
                                        <ReceiptView/>
                                    </Route>
                                    <Route exact path={"*"}>
                                        <PageNotFoundView/>
                                    </Route>
                                </Switch>
                            </NetaxeptCallback>
                        </SnackbarProvider>
                    </Router>
                </div>
            </Suspense>
        </I18nextProvider>
    );
}

export default App;
