import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ArticleBase,
  ArticleBody,
  ArticleContent,
  ArticleGrid,
} from '../components/article';
import Header from '../components/header/Header';
import HeaderExpanded from '../components/header/HeaderExpanded';
import Footer from '../components/footer/Footer';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import frontPageHeaderImage from '../assets/headerimage.jpg';


const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    width: '100%',
    backgroundColor: 'white',
    display: 'block',
  },
  button: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    borderRadius: 0,
    borderWidth: '2px',
    borderColor: 'black',
    fontFamily: 'Akkurat, sans-serif',
    fontSize: 14,
    width: '100%',
  },
  bodyText: {
    padding: theme.spacing(4)
  }
}));

const FrontPageView = () => {
  const classes = useStyles();
  const { t } = useTranslation('portal');
  const logoText = t('logoText');
  const pageTitle = t('frontPageTitle');
  const description = '';
  const backgroundColor = '#24ad69';
  const expanded = true;

  return (
    <div className={classes.pageWrapper}>
      <ArticleBase id="top">
        <Header
          logoText={logoText}
          imageUrl={frontPageHeaderImage}
          backgroundColor={backgroundColor}
          expanded={expanded}
        >
          <HeaderExpanded
            imageUrl={frontPageHeaderImage}
            title={pageTitle}
            description={description}
          />
        </Header>

        <ArticleGrid>
          <ArticleBody>
            <ArticleContent>
              <div className={classes.bodyText}>
                <br />
                <Typography variant={'h6'} className={classes.text}>
                  {t('frontPageParagraph1')}
                </Typography>
                <br />
                <Typography variant={'h6'} className={classes.text}>
                  {t('frontPageParagraph2')}
                </Typography>
                <br />
                <Typography variant={'h6'} className={classes.text}>
                  <Link href="https://kulturit.org/museumsbillett" variant="body2">
                    <h1>{'https://kulturit.org/museumsbillett'}</h1>
                  </Link>
                </Typography>
                <br />
                <br />
              </div>
            </ArticleContent>
          </ArticleBody>
        </ArticleGrid>
        <Footer />
      </ArticleBase>
    </div>
  );
};

export default FrontPageView;
