import React, {useState} from "react"
import { makeStyles } from '@material-ui/core/styles';
import Color from 'color';
import {useTranslation} from "react-i18next";


const getContrast = (color) => {

    color = Color(color);

    if (color.isDark()) {
        return "white"
    } else {
        return "black"
    }

}


const useStyles = makeStyles(theme => ({
    steps: {
        display: "flex",
        fontSize: 16,
        fontFamily: "Akkurat, sans-serif",
        fontWeight: "normal",

        marginBottom: "0.8em",
        marginLeft: "0.8em",

        [theme.breakpoints.up('md')]: {
            fontSize: 18,
            marginLeft: 0
        },

        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
            marginLeft: 0
        },

        overflow: "hidden",


    },
    step: {
        display: "flex",
        fontSize: "0.75em",
        textAlign: "left",
        alignItems: "center",

        "& + $step": {
            marginLeft: "0.5em",
        },

        [theme.breakpoints.up('md')]: {
            width: "auto",
            padding: "0.4em 0",
        },

        opacity: .35,

        "&[aria-expanded=true]": {
            opacity: 1
        },

        "&[aria-expanded=true]:hover": {
            cursor: "pointer",

            "& > $icon": {
                backgroundColor: props => { return props.color },
                color: props => { return props.backgroundColor },

            }

        },

        "&[aria-selected=true]": {
            opacity: 1,

            "& > $icon": {
                backgroundColor: props => { return props.color },
                color: props => { return props.backgroundColor },
            }

        },


    },
    icon: {
        fontSize: "1em",
        width: "2em",
        height: "2em",
        border: "1px solid",
        color: "inherit",
        borderColor: props => { return props.color },
        borderRadius: "50%",

        fontFamily: "Akkurat mono, monospace",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",

    },
    label: {
        marginLeft: "0.5em",
        marginRight: "0.25em",

        "&[aria-expanded=true]": {
            display: "none",
        },

        "&[aria-selected=true]": {
            fontWeight: "bold",
            display: "block",
        },

        [theme.breakpoints.up('md')]: {
            display: "block",
        }

    }

}));




const CheckoutSteps = ({backgroundColor, step, steps = [], onSelect}) => {
    const { t } = useTranslation('portal');
    const color = backgroundColor && getContrast(backgroundColor)
    const classes = useStyles({backgroundColor, color})
    const currentStep = step
    let expanded = true
    const [stepLabels, setStepLabels] = useState({
        calendar: t('stepCalendar'),
        tickets: t('stepTickets'),
        addons: t('stepAddons'),
        register: t('stepregister'),
        receipt: t('stepReceipt'),
    });



    return (
        <div className={classes.steps}>
            { steps && steps.map((step, index) => {

                if (typeof step === "string") {
                    step = {
                        label: stepLabels[step] || step,
                        value: step,
                        step: step
                    }
                }

                const { label, value } = step

                if (currentStep === value) {
                    expanded = false
                }

                const selected = currentStep === value

                return (
                    <div aria-expanded={expanded} aria-selected={selected} className={classes.step} onClick={() => onSelect && onSelect(step)} key={index}>
                        <div aria-expanded={expanded} aria-selected={selected} className={classes.icon}>{index+1}</div>
                        <div aria-expanded={expanded} aria-selected={selected} className={classes.label}>{label}</div>
                    </div>
                )
            })}
        </div>
    )
}

export default CheckoutSteps