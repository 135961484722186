import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    vatLine: {
        paddingLeft: '20px',
        paddingRight: '20px',
        marginTop: theme.spacing(1),
        textAlign: '-internal-center',
    },
    vatHeader: {
        textAlign: 'left',

        fontWeight: "bold",
    },
    divider: {
        borderTop: 'dotted',
        borderBottom: 0,
        borderWidth: '2px',
        color:'grey',
    },
    vatTable: {
        width:'100%',
        fontSize: '1rem',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    lastColumn: {
        textAlign: 'right',
    }
}));


const ReceiptLine = ({order}) => {
    const classes = useStyles()
    const [vatMap, setVatMap] = useState();
    const { t } = useTranslation('portal');

    useEffect(() => {
        let vatMap = new Map()
        order.orderLines.forEach(orderLine => {
            if (vatMap.has(orderLine.vat)) {
                vatMap.set(orderLine.vat, vatMap.get(orderLine.vat) + (orderLine.price * orderLine.quantity));
            } else {
                vatMap.set(orderLine.vat, orderLine.price * orderLine.quantity);
            }
        })

        setVatMap(vatMap)
    }, [order])


    return (
        <>
            <table className={classes.vatTable}>
                <tbody>
                <tr className={classes.vatHeader}>
                    <th>{t('vat%')}</th>
                    <th>{t('basis')}</th>
                    <th>{t('vat')}</th>
                    <th className={classes.lastColumn}>{t('total')}</th>
                </tr>

                {vatMap &&
                [...vatMap.keys()].map(vat => {
                    return (
                        <tr key={vat} className={classes.vatLine}>
                            <td>{vat}%</td>
                            <td>{(vatMap.get(vat) /(1+(vat)/100)).toLocaleString('de-DE', {minimumFractionDigits: 2})}</td>
                            <td>{(vatMap.get(vat)- (vatMap.get(vat) /(1+(vat)/100))).toLocaleString('de-DE', {minimumFractionDigits: 2})}</td>
                            <td className={classes.lastColumn}>{(vatMap.get(vat)).toLocaleString('de-DE', {minimumFractionDigits: 2})}</td>
                        </tr>
                    )
                })
                }
                </tbody>
            </table>
            <hr className={classes.divider}/>
        </>
    )
}

export default ReceiptLine