import React from "react"
import ArticleSection from "../article/ArticleSection";
import TicketList from "../tickets/TicketList";
import {useTranslation} from "react-i18next";

const SelectTickets = (props) => {

    const { t } = useTranslation('portal');

    const {
        title = props.type === 'seasonal_pass' ? t('selectProducts') : t('selectTickets'),
        description,
        bodytext,
        items,
        discounts,
    } = props.ticketData

    return (
        <ArticleSection
            title={title}
            description={description}
            bodytext={bodytext}
        >
            <TicketList
                mostRecentErrorMessage={props.mostRecentErrorMessage}
                items={items}
                discounts={discounts}
                onChange={props.onChange}
                moreTicketsAvailable={props.moreTicketsAvailable}
            />
        </ArticleSection>
    )

}


export default SelectTickets