import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import ProgramListItem from "./ProgramListItem"

const useStyles = makeStyles(theme => ({
    list: {
        display: "flex",
        flexDirection: "column",
        margin: theme.spacing(1.5, 0),
        [theme.breakpoints.down('xs')]: {
            border: "none",
            marginLeft: theme.spacing(-1.5),
            marginRight: theme.spacing(-1.5),
            "& > *": {
                borderLeft: "none",
                borderRight: "none"
            }
        },
        "& > *": {
            border: "1px solid",
            borderColor: theme.palette.divider,
        },
        "& > * + *": {
            borderTop: "none",
        },
        "& > * + [aria-expanded=true]": {
            marginTop: theme.spacing(1),
        },
        "& > [aria-expanded=true]": {
            "& + *": {
                marginTop: theme.spacing(1),
                borderTop: "1px solid",
                borderColor: theme.palette.divider,
            },
            "& + [aria-expanded=true]": {
                borderColor: "black",
            }
        }
    },
}));

const ProgramList = ({items = [], onChange, preview = false}) => {

    const classes = useStyles()

    return (
        <div className={classes.list}>
            {items.map((item, index) => <ProgramListItem {...item} onChange={onChange} key={index} preview={preview} /> )}
        </div>
    )
}


export default ProgramList