import React from "react"
import ButtonBase from "@material-ui/core/ButtonBase"
import { makeStyles } from '@material-ui/core/styles';

import Color from 'color';

const getContrast = (color) => {

    color = Color(color);

    if (color.isDark()) {
        return "white"
    } else {
        return "black"
    }

}


const useStyles = makeStyles(theme => ({
    button: {
        display: "flex",
        width: props => { return props.width },
        justifyContent: "flex-start",
        backgroundColor: props => { return props.backgroundColor },
        color: props => { return getContrast(props.backgroundColor) },
        boxShadow: theme.shadows[1],
        border: "1px solid",
        borderColor: "rgba(0,0,0,.26)",
        padding: 1,
        overflow: "visible",

        "&[data-status=full]": {
            borderColor: "rgba(0,0,0,.12)",
            backgroundImage: "repeating-linear-gradient(-45deg, rgba(0,0,0,.12), rgba(0,0,0,.12) 1px, transparent 1px, transparent 9px)",
            boxShadow: "none",
            color: theme.palette.text.secondary,

            "& img": {
                filter: "grayscale(100%)",
                opacity: .5
            }

        },

        "& > *": {
            flexGrow: 1
        },

        "&:hover": {
            boxShadow: theme.shadows[4],
        },

        "&[aria-selected=true]": {
            borderColor: "black",
            borderWidth: 2,
            padding: 0,
            boxShadow: "none",

            "&:hover": {
                backgroundColor: "black",
                color: "white",
                boxShadow: "none",
            }

        },

    },
}));

const EventButton = ({width = "100%", backgroundColor = "white", status, selected, onClick, children}) => {
    const classes = useStyles({backgroundColor, width})
    let disabled

    if (status === 'full') {
        disabled = true
    }

    return (
        <ButtonBase className={classes.button}
            aria-selected={selected}
            data-status={status}
            disabled={disabled}
            onClick={onClick}>
                {children}
        </ButtonBase>
    )

}

export default EventButton