import React from "react"

import CheckoutBase from "../CheckoutBase";
import SelectEvent from "../selectEvent";
import ProgramPreview from "../program/ProgramPreview";
import CheckoutSections from "../CheckoutSections";
import ArticleSection from "../article/ArticleSection";
import {useTranslation} from "react-i18next";

const StepCalendar = (props) => {
    const {
        date,
        id,
        sections = [],
        calendar = {
            title: 'selectDay',
            description: "",
        },
        program = {},
        statusByDate = {},
        statusById = {},
        onSelect,
        loadingCalendar,
        hasEarlierEvents,
        hasLaterEvents,
        activeCalendarMonth,
    } = props

    // const items = calendar && calendar.events
    const { t } = useTranslation('portal');
    const sidebar = date && program && program.events && <ProgramPreview date={date} {...program} />


    const _onSelect = ({date, id, title, location}) => {
        if (date) {
            onSelect && onSelect({date, id, title, location})
        } else {
            onSelect && onSelect({date})
        }
    }

    return (
        <CheckoutBase {...props} sidebar={sidebar} expanded={true}>
            <ArticleSection title={props.translatedProduct?.calendar?.title ||  t(calendar.title)} description={calendar.events ? "" : t("noUpcomingEvents")}>
            <SelectEvent
                {...calendar}
                layout={props.layout}
                calendar={calendar}
                statusByDate={statusByDate}
                statusById={statusById}
                date={date}
                id={id}
                slotsTitle={t('selectTime')}
                loadingCalendar={loadingCalendar}
                hasEarlierEvents={hasEarlierEvents}
                hasLaterEvents={hasLaterEvents}
                onSelect={_onSelect}
                activeCalendarMonth = {activeCalendarMonth}/>
            <CheckoutSections sections={sections} program={program} />
            </ArticleSection>
        </CheckoutBase>
    )

}

export default StepCalendar;