import React from "react"
import {makeStyles} from '@material-ui/core/styles';

import ArticleSection from "../article/ArticleSection";

const useStyles = makeStyles(theme => ({
    fieldset: {
        marginBottom: theme.spacing(2)
    },
    field: {
        "& + $field": {
            marginTop: theme.spacing(2)
        }

    }

}));

const CustomObjectFieldTemplate = ({title, description, properties}) => {

    const classes = useStyles()

    return (
        <ArticleSection title={title} description={description}>
            <div className={classes.fieldset}>
                {properties.map(prop => {
                    return (
                        <div className={classes.field} key={prop.name}>{prop.content}</div>
                    )
                })}
            </div>
        </ArticleSection>
    )


}

export default CustomObjectFieldTemplate