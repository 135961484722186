import React from "react"
import {makeStyles} from '@material-ui/core/styles';
import Color from 'color';
import {ArticleGrid} from "../article";
import Logo from "../logo/Logo";
import LanguageSelector from "./LanguageSelector";


const getContrast = (color) => {

    color = Color(color);

    if (color.isDark()) {
        return "white"
    } else {
        return "black"
    }

}

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: props => { return props.backgroundColor }, // theme.palette.background.default,
        color: props => { return props.color }, // theme.palette.background.default,
        display: "block",
        width: "100%",

        fontSize: 18,

        [theme.breakpoints.up('sm')]: {
            fontSize: 18
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 20
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 24
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 30
        },

    },
    logo: {
        position: "relative",
        fontSize: 16,
        margin: "0.8em",

        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",

        [theme.breakpoints.up('md')]: {
            fontSize: 18,
            margin: "0.8em 0",
        },

        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
            margin: "0.8em 0",
        },
    },
}));

const Header = ({backgroundColor, logoText, expanded, children, enableLanguageSelector = false}) => {

    const color = backgroundColor && getContrast(backgroundColor)
    const classes = useStyles({backgroundColor, color})

    return (
        <header className={classes.header} aria-expanded={expanded}>
            <LanguageSelector backgroundColor={backgroundColor}/>
            <ArticleGrid>
                <Logo className={classes.logo} logoText={logoText} />
                {children}
            </ArticleGrid>
        </header>
    )
}

export default Header