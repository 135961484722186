import React from "react"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    table: {
        width: "100%",
//        borderCollapse: "collapse",
        display: "flex",
        flexDirection: "column",
        fontFamily: "Akkurat, sans-serif",
        fontSize: 14,
        "& + $table": {
        },
        "& caption": {
            fontFamily: "Akkurat, sans-serif",
            fontSize: 16,
            fontWeight: "bold",
            padding: theme.spacing(1, 1.5),
            textAlign: "left"
        },
        "& tr": {
            display: "flex",
            justifyContent: "space-between",
            padding: theme.spacing(1, 1.5),
        },
        "& th": {
            fontWeight: "normal",
            textAlign: "left",
            margin: 0
        },
        "& td": {
            fontWeight: "normal",
            textAlign: "right",
            margin: 0
        },
        "& tbody": {
            display: "flex",
            flexDirection: "column",
            "& tr + tr": {
                backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, .5) 40%, rgba(255, 255, 255, 0) 20%)",
                backgroundPosition: "top",
                backgroundSize: "3px 1px",
                backgroundRepeat: "repeat-x",
            },
        },
        "& tfoot": {
            display: "flex",
            flexDirection: "column",
            "& tr + tr": {
                backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, .5) 40%, rgba(255, 255, 255, 0) 20%)",
                backgroundPosition: "top",
                backgroundSize: "3px 1px",
                backgroundRepeat: "repeat-x",
            },
            "& tr:first-child": {
                padding: theme.spacing(1.5),
                "& > *": {
                    fontSize: 16,
                    fontWeight: "bold",
                },
                "& + tr": {
                    backgroundImage: "none"
                }
            },
        }
    },
    item: {
        width: "100%",
        display: "flex",
        "& * + *": {
            flexBasis: 0,
            marginLeft: theme.spacing(.5)
        }
    },
    price: {
        color: theme.palette.text.secondary,
        textAlign: "right",
        width: 90
    },
    total: {
        textAlign: "right",
        width: 120
    },
    label: {
        flexGrow: 1,
        "& * + *": {
            marginLeft: theme.spacing(.5)
        }
    },
}));



const CartTable = ({title, count, total, items, footer, details = false}) => {

    const classes = useStyles()

    if (items && !Array.isArray(items)) {
        items = Object.values(items)
    }

    if (!count && items && items.length) {
        count = items.length
    }

    if (!count && !footer) {
//        return false
    }

    const formatValue = (value = 0) => {

        if (!details) {
            return value.toLocaleString('de-DE', {maximumractionDigits: 2}) + ",-"
        }

        return value.toLocaleString('de-DE', {minimumFractionDigits: 2})
    }

    const CartTableRow = ({count, id, title, description, price, total}) => {

        if (!count) {
            return false
        }

        price = total / count

        return (
            <tr>
                <th className={classes.item}>
                        <div className={classes.count}>{count} ×</div>

                        <div className={classes.label}>
                            <span className={classes.title}>{title}</span>
                            { (description && <span className={classes.description}>({description})</span>) || "" }
                        </div>

                        { details &&
                            <div className={classes.price}>@{formatValue(price)}</div>
                        }

                </th>
                <td className={classes.total}>
                    {formatValue(total)}
                </td>
            </tr>
        )

    }

    const CartTableFoot = () => {

        return (
            <tfoot>
                { footer && footer.map((item, index) => {
                    return (
                        <tr key={index}>
                            <th>{item.label}</th>
                            <td>{formatValue(item.value)}</td>
                        </tr>
                    )
                })}
            </tfoot>
        )

    }

    if (!count && !footer) {
        return false
    }
    
    return (
        <table className={classes.table}>
            { title && <caption className={classes.caption}>{title}</caption> }
            { (footer && <CartTableFoot />) || null }
            { items && 
                <tbody>
                    {items.map((item, index) => <CartTableRow {...item} key={index} />)}
                </tbody>
            }

        </table>
    )
    
}

export default CartTable