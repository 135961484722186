import moment from 'moment';

export const getEventDetails = (event) => {

    const {allDay, dtStart, dtEnd} = event

    let start, end
    
    if (allDay) {
        start = moment(dtStart).format("YYYY-MM-DD")
        end = moment(dtEnd).format("YYYY-MM-DD")
    } else {
        start = moment(dtStart).format("YYYY-MM-DDTHH:mm")
        end = moment(dtEnd).format("YYYY-MM-DDTHH:mm")
    }

    const date = moment(start).format("YYYY-MM-DD")
    const startTime = start && moment(start).format("HH:mm");
    const endTime = end && moment(end).format("HH:mm");
    const time = (allDay && "Hele dagen") || (startTime !== endTime && startTime + "–" + endTime) || startTime

    return {
        ...event,
        date: date,
        time: time,
    }

}

export default getEventDetails