import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment"
import "moment/locale/nb";
import "moment/locale/nn";
import "moment/locale/sv";
moment.locale('nb')

const useStyles = makeStyles(theme => ({
    slot: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "7em",
        minWidth: "7em",
    },
    time: {
        fontFamily: "Akkurat, sans-serif",
        fontStyle: "normal",
        lineHeight: 1.5,
    }

}));

const CalendarSheet = ({start, dtStart, end, dtEnd}) => {

    const classes = useStyles()

    const startTime = (start && moment(start).format("HH.mm")) || (dtStart && moment(dtStart).format("HH.mm"));
    const endTime = (end && moment(end).format("HH.mm")) || (dtEnd && moment(dtEnd).format("HH.mm"));

    const time = startTime !== (endTime && startTime + "–" + endTime) || startTime

    return (
        <div className={classes.slot}>
            <div className={classes.time}>{time}</div>
        </div>
    )

}

export default CalendarSheet