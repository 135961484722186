import React from "react"
import { makeStyles } from '@material-ui/core';
import ButtonBase from "@material-ui/core/ButtonBase"

const useStyles = makeStyles(theme => ({
    program: {
        backgroundColor: "white",
        border: "1px solid",

        [theme.breakpoints.down('sm')]: {
            borderLeft: "none",
            borderRight: "none"
        },

        "& > *": {
            flexGrow: 1
        },

        "&[disabled]": {
            borderColor: "rgba(0,0,0,.12)",
            backgroundImage: "repeating-linear-gradient(-45deg, rgba(0,0,0,.12), rgba(0,0,0,.12) 1px, transparent 1px, transparent 9px)",
            boxShadow: "none",
            color: theme.palette.text.secondary,
        },

        "&[aria-expanded=true]": {
            border: "1px solid",
            borderColor: "black",

            [theme.breakpoints.down('sm')]: {
                borderLeft: "none",
                borderRight: "none"
            },
        }

    },
    grid: {
        display: "flex",
        flexDirection: "column",
    }

}));


const ProgramBase = ({className, disabled, selected, expanded, stock = true, onClick, children}) => {

    const classes = useStyles()

    if (onClick) {
        return (
            <ButtonBase className={classes.program}
                aria-selected={selected}
                aria-expanded={expanded}
                data-stock={stock || 0 }
                onClick={!disabled && onClick}
                disabled={disabled}>
                <div className={className || classes.grid}>
                    {children}
                </div>
            </ButtonBase>
        )
    }

    return (
        <div className={classes.program}
            aria-selected={selected}
            aria-expanded={expanded}
            data-stock={stock || 0 }
            onClick={onClick}
            disabled={disabled}>
            <div className={className || classes.grid}>
                {children}
            </div>
        </div>
    )

}

export default ProgramBase