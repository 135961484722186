import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";


const ValidateTicketDialog = ({showConfirmValidate , handleClose, validateTicket}) => {
    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={showConfirmValidate}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Advarsel
            </DialogTitle>
            <DialogContent dividers>
                <Typography>Valideringen skal bare gjøres foran en kontrollør. Ønsker du å validere billetten nå?</Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Nei
                </Button>
                <Button autoFocus onClick={validateTicket} color="primary">
                    Ja
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ValidateTicketDialog