import React from 'react';
import {Document, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import _ from "lodash"
import PdfPass from "./PdfPass"

const styles = StyleSheet.create({
    page: {
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: '#fff'
    },
    header: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        margin: 10,
        flexDirection: "row",
        justifyContent: "space-between"
    },
    headerText: {
        fontSize: 10
    },
    passes: {
        flexDirection: "row",
        flexWrap: "wrap",
        margin: "auto",
        width: "auto",
        maxWidth: "166mm",
    },
    pass: {
        width: "81mm",
        height: "auto",
        margin: "1mm"
    },

});

const PdfPasses = ({
                       title = "Dine billetter",
                       author = "KulturIt",
                       subject = "",
                       keywords = "",
                       creator = "",
                       producer = "",
                       passes = [],
                       test,
                       order,
                       product,
                        imageUrl,
                   }) => {
    const locale = localStorage.getItem("i18nextLng")
    const passesPerPage = _.chunk(passes, 4)
    const count = passes.length
    const pages = passesPerPage.length
    const norwegianHeaderTexts = {ticketCount: "Antall billetter: ", mainTicket: "Samlebillett: ",}
    const englishHeaderTexts = {ticketCount: "Number of tickets: ", mainTicket: "Main ticket: ",}
    const getHeaderTextsByLocale = () => {
        if (!locale || locale === "no" || locale === "nn" || locale === "nb") {
            return  norwegianHeaderTexts
        } else {
            return englishHeaderTexts
        }
    }

    const headerTexts = getHeaderTextsByLocale(locale)

    const getOrderLineForTicket = (orderLineId, orderLines) => {
        return orderLines.find(orderLine => {
            return orderLine.id === orderLineId;
        });
    }

    return (
        <Document title={title} author={author} subject={subject} keywords={keywords} creator={creator} producer={producer}>
            { passesPerPage.map((pageOfPasses, index) => {
                const page = index+1
                return (
                    <Page size="A4" style={styles.page} key={index}>
                        <View style={styles.header}>
                            <Text style={styles.headerText}>{headerTexts.ticketCount} {count}</Text>
                            <Text style={styles.headerText}>{headerTexts.mainTicket} {passes[0].mainTicket?.code}</Text>
                            <Text style={styles.headerText}>{page}/{pages}</Text>
                        </View>

                        <View style={styles.passes}>
                            { pageOfPasses.map((pass, index) => {
                                return <View style={styles.pass} key={index}>
                                    <PdfPass
                                        code={pass.code}
                                        orderLine={getOrderLineForTicket(pass.orderLineId, order.orderLines)}
                                        category={getOrderLineForTicket(pass.orderLineId, order.orderLines).productPriceCategoryName}
                                        title={getOrderLineForTicket(pass.orderLineId, order.orderLines).productName}
                                        price={getOrderLineForTicket(pass.orderLineId, order.orderLines).price}
                                        logoText={getOrderLineForTicket(pass.orderLineId, order.orderLines).museumName}
                                        location={getOrderLineForTicket(pass.orderLineId, order.orderLines).museumName}
                                        startDate={getOrderLineForTicket(pass.orderLineId, order.orderLines).startDate}
                                        date={getOrderLineForTicket(pass.orderLineId, order.orderLines).startDate}
                                        time={getOrderLineForTicket(pass.orderLineId, order.orderLines).museumName}
                                        order={order}
                                        test={test}
                                        product={product}
                                        imageUrl={imageUrl}
                                    />
                                </View>
                            })}
                        </View>
                    </Page>
                )
            })}
        </Document>
    )
}

export default PdfPasses