import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import styled, { keyframes } from 'styled-components'



const useStyles = makeStyles(theme => ({
    statusText: props => ({
        fontSize: '2rem',
        position: 'absolute',
        top: '50%',
        left: '50%',
        padding: theme.spacing(2),
        borderStyle: 'dotted',
        transform: 'translate(-50%, -50%) rotate(45deg)',
        backgroundColor: props.backgroundColor,
        textAlign: 'center'
    }),
}));

function blinkingEffect() {
    return keyframes`
    50% {
      opacity: 0.7;
    }
  `;
}
const AnimatedComponent = styled.div`
  animation: ${blinkingEffect} 2s linear infinite;
`

const TicketStatusSticker = ({statusText, backgroundColor}) => {
    const classes = useStyles({backgroundColor: backgroundColor})
    return (
        <AnimatedComponent>
            <div className={classes.statusText}>{statusText}</div>
        </AnimatedComponent>
    )
}

export default TicketStatusSticker