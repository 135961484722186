import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from  "@material-ui/core/ButtonBase"

const useStyles = makeStyles(theme => ({
    status: {
        border: "1px solid",
        borderRadius: 12,

        whiteSpace: "nowrap",

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",

        fontFamily: "Akkurat, sans-serif",
        fontSize: 12,
        fontWeight: "bold",
        lineHeight: 1,

        padding: theme.spacing(.75, 1.5),
        margin: "auto",

        "&[data-status=default]": {
            borderColor: "black",
            backgroundColor: "black",
            color: "white"
        },

        "&[data-status=limit]": {
            borderColor: "red",
            backgroundColor: "red",
            color: "white"
        }

    },
    button: {
        whiteSpace: "nowrap",

        borderRadius: 20,
        position: 'relative',
        backgroundColor: "inherit",
        color: "inherit",
        border: '2px solid',
        fontSize: 16,
        lineHeight: 1.25,
        fontWeight: "bold",
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 24,
        paddingRight: 24,

        fontFamily: "Akkurat, sans-serif",

        '&:focus': {
            borderRadius: 20,
        },
        
    },
}));

const labels = {
    "default": "Ledige plasser",
    "book": "Reserver plass",
    "limit": "Få plasser igjen",
    "capacity": "[capacity] plasser",
    "full": "Fullt",
}


const ProgramStatus = ({className, expanded, bookable, label, status = "default", capacity = true, onClick}) => {

    const classes = useStyles()

    if (!bookable) {
        return false
    }

    const disabled = !capacity

    if (!capacity) {
        status = "full"
    } else if (typeof capacity === "number") {
        status = "limit"
    }

    if (status === "limit" && capacity) {
        label = labels['capacity'].replace("[capacity]", capacity)
    } else if (status) {
        label = labels[status]
    }

    if (expanded) {
        status = "expanded"
    }

    if (onClick) {
        return (
            <ButtonBase className={classes.status} data-status={status} disabled={disabled} onClick={onClick}>{label}</ButtonBase>
        )
    }

    return (
        <div className={className || classes.status} data-status={status}>
            {label}
        </div>
    )


}

export default ProgramStatus