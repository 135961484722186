import React, {useState} from "react"
import {makeStyles} from '@material-ui/core/styles';

import Form from "@rjsf/core";

import ButtonSubmit from "./ButtonSubmit"
import {generateJsonSchema, generateUiSchema} from "./SchemaGenerator"

import registry from "./registry"
import TextWidget from "./TextWidget";
import CheckboxWidget from "./CheckboxWidget";
import {useTranslation} from "react-i18next";
import CommentWidget from "./CommentWidget";
import CheckboxesWidget from "./CheckboxesWidget";
import DiscountCodeWidget from "./DiscountCodeWidget";
import EmailWidget from "./EmailWidget";
import {CircularProgress, LinearProgress} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    form: {
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",

        "& fieldset": {
            border: "none",
            padding: 0,
            margin: 0,

            "& > * + *": {
                marginTop: theme.spacing(2)
            }
        },

        "& > .errors": {
            display: "none",
        },

        "& .MuiTypography-root": {
            fontFamily: "Akkurat, sans-serif"
        },

        "& .MuiFormLabel-root": {
            fontFamily: "Akkurat, sans-serif",
        },
        "& .MuiInputBase-root": {
            fontFamily: "Akkurat, sans-serif",
        },

    },

    errors: {
        display: "flex",
        flexDirection: "column",
        fontFamily: "Akkurat, sans-serif",
        fontSize: 16,
        lineHeight: 1.5,
        margin: "1.5em 0",
        border: "2px solid",
        borderColor: "red",
        padding: "1.5em"
    },
    footer: {
        backgroundColor: "white",
        padding: theme.spacing(1.5),
        position: "fixed",
        zIndex: 2,
        top: "auto",
        left: 0,
        right: 0,
        bottom: 0,
        boxShadow: theme.shadows[4],
        [theme.breakpoints.up('md')]: {
            padding: 0,
            position: "relative",
            boxShadow: "none"
        }
    },
}));

const RegistrationForm = ({formData = {}, errors = [], onChange, onSubmit, disabledSubmit, ...props}) => {
    const classes = useStyles()
    const [disabled, setDisabled] = useState(false)
    const { t } = useTranslation('portal');
    const answerAlternatives = props.registration.answerAlternatives ? JSON.parse(props.registration.answerAlternatives).map( alternative => {
            return alternative['value'];
    }) : []

    const formatTotal = () => {
        return formData?.total ? formData.total.toLocaleString('de-DE', {minimumFractionDigits: 2}) : 0
    }

    const getLabel = () => {
        if (errors.length >= 2) {
            return t("fillInnFields")
        } else {
            return t("payCurrency") + " " + formatTotal()
        }
    }

    const _onChange = ({errors, formData, ...props}) => {
        setDisabled(false)
        errors.length && setDisabled(true)
        if ( formData.accept === false ) {
            setDisabled(true)
        }
        onChange && onChange({errors, formData, ...props})
    }

    const isValidEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const validate = (formData, errors) => {
        const numbers = /^[0-9]*$/
        if (formData.registration.email !== formData.registration.repeatEmail) {
            errors.registration.repeatEmail.addError(t("differentEmailErrorMessage"));
        }

        if (formData.total !== 0 && !formData.registration.paymentType.id && props.payTypes.length > 1) {
            errors.registration.paymentType.addError(t("noPaymentMethodSelectedErrorMessage"));
        }

        if (formData.registration.email && !isValidEmail(formData.registration.email)) {
            errors.registration.repeatEmail.addError(t("invalidEmail"));
        }

        if (formData.registration.repeatEmail && !isValidEmail(formData.registration.repeatEmail)) {
            errors.registration.repeatEmail.addError(t("invalidEmail"));
        }
        if (props.registration.rubicIntegrationEnabled) {
            if (formData.registration.postalCode?.length !== 4) {
                errors.registration.postalCode.addError(t("invalidPostalCode"));
            }

            if (!numbers.test(formData.registration?.postalCode)) {
                errors.registration.postalCode.addError(t("invalidPostalCode"));
            }

            if (formData.registration?.rubicMemberId && !numbers.test(formData.registration?.rubicMemberId)) {
                errors.registration.rubicMemberId.addError(t("invalidRubicMemberId"));
            }
        }

        return errors;
    }

    return (
        <Form
            {...registry}
            liveValidate={true}
            className={classes.form}
            formData={formData}
            onChange={_onChange}
            widgets={{
                TextWidget,
                CommentWidget,
                DiscountCodeWidget,
                CheckboxWidget,
                CheckboxesWidget: CheckboxesWidget,
                "email": EmailWidget,
            }}
            fields={registry}
            validate={validate}
            onSubmit={onSubmit}
            schema={generateJsonSchema(props.payTypes, formData.total, props.museumSettings?.termsOfSaleUrl,
                props.registration.question, answerAlternatives,
                props.registration.mandatoryQuestion, props.order.id, props.applyDiscountCode,
                props.registration.rubicIntegrationEnabled, props.registration.isGiftProduct)}
            uiSchema={generateUiSchema(props.registration.title,
                props.registration.description,
                props.registration.newsletterOptionEnabled,
                props.registration.registrationFormFreetextField,
                props.registration.question,
                props.registration.multipleChoice,
                props.backgroundColor,
                !(props.hasDiscountCodes && formData?.total),
                props.registration.rubicIntegrationEnabled,
                props.registration.isGiftProduct,
                props.rubicInfo.prefilled, t)}>
            <footer className={classes.footer}>
                <ButtonSubmit
                    disabled={disabled || disabledSubmit}
                    type="submit">
                    {getLabel()}{disabledSubmit && <LinearProgress color="primary" />}
                </ButtonSubmit>
            </footer>
        </Form>
    )
}


export default RegistrationForm
