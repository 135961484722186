import {museumTranslationKey} from "./staging";

const percentageOfSaleLimit = (saleLimit, ticketsSold, ticketsReserved= 0) => {
    let percentage = 0
    percentage = ((ticketsSold + ticketsReserved) / saleLimit) * 100
    return percentage
}

export const getTranslationByLocale = (obj, property, locale) => {
    if (obj?.translations) {
        const translation = obj.translations.find(trans => trans.languageLocale === locale)
        if (translation) {
            return translation[property]
        }
    }

    return obj[property]
}

const getCheckoutFromApiData = (product,  productId, locale, t) => {

    const parentKey = museumTranslationKey({
        id: productId,
        type: 'product'
    })

    const  {
        name,
        description,
        type,
        museumName,
        museumId,
        hasAddons= false,
        colorHeader = "red",
        imageUrl,
        layout = "list",
        scheduleEvents = [],
        prices = [],
        museumSettings,
        newsletterOptionEnabled,
        rubicIntegrationEnabled,
        registrationFormFreetextField,
        registrationFormQuestion,
        registrationFormAnswers,
        registrationFormMultipleChoice,
        registrationFormQuestionMandatory,
        totalScheduledEvents,
        hasEarlierEvents,
        hasLaterEvents,
        hasDiscountCodes,
        paymentTypes = [],
        apiData,
        isGiftProduct
    } = product

    // Data from database that is used in most sites of the solution .

    // Headerdata
    const event = {
        title: getTranslationByLocale(product, 'name', locale),
        description: getTranslationByLocale(product, 'description', locale),
        location: museumSettings?.screenName || museumName,
        hasAddons: hasAddons,
        logoText: museumSettings?.screenName || museumName,
        hasEarlierEvents: hasEarlierEvents,
        hasLaterEvents: hasLaterEvents,
        backgroundColor: colorHeader ? colorHeader : "green",
        imageUrl: imageUrl,
        layout: scheduleEvents && scheduleEvents.length < 10 && !hasEarlierEvents && !hasLaterEvents ? "slotsByDate" : "calendar",
    }

    // Product info for first step
    const calendar = {
        title: t('selectDay'),
        description: "",
        events: scheduleEvents?.map((item, index) => {
            let status = null
            if (item.saleLimit) {
                if (item.ticketsSold >= item.saleLimit) {
                    status = "full"
                } else if (item.ticketsReserved && (item.ticketsSold + item.ticketsReserved >= item.saleLimit)) {
                    status = "full"
                } else {
                    status = (item.saleLimit - (item.ticketsSold + item.ticketsReserved))
                }
            }

            // if (item.saleLimit && ((item.ticketsSold > 0) || (item.ticketsReserved > 0))) {
            //     if (item.ticketsSold >= item.saleLimit) {
            //         status = "full"
            //     } else if (item.ticketsReserved && (item.ticketsSold + item.ticketsReserved >= item.saleLimit)) {
            //         status = "full"
            //     } else if (item.ticketsSold > 0 && (percentageOfSaleLimit(item.saleLimit, item.ticketsSold, item.ticketsReserved)) >= 90) {
            //         status = "limit"
            //     }
            // }

                return {
                    id: "product-" + index,
                    scheduleEventId: item.id,
                    title: getTranslationByLocale(item, 'name', locale),
                    location: item.location || event.location,
                    date: item.startDate,
                    recurNumber: item.recurNumber,
                    dtStart: item.startDate + " " + item.startTime,
                    dtEnd: item.endDate ? item.endDate + " " + item.endTime : "",
                    allDay: item.isFullDay,
                    startTime: item.startTime,
                    endTime: item.endTime,
                    status: status
                }
            })
    }

    // Payment options
    const payTypes = paymentTypes?.map((item, index) => {
                return {
                    id: item.id,
                    museumName: item.museumName,
                    name: item.name,
                    status: item.status,
                }
        })

    // Data for step where tickets are selected
    const tickets = {
        title: type === "seasonal_pass" ? t('selectProducts') : t('selectTickets'),
            items: prices?.map((price, index) => {
                return {
                    id: "ticket-" + index,
                    title:   getTranslationByLocale(price, 'name', locale),
                    description: getTranslationByLocale(price, 'description', locale),
                    price: price.amount,
                    priceCategoryId: price.priceCategoryId,
                    groupTicket: price.groupTicket,
                    excludedFromCapacityCalculation: price.excludedFromCapacityCalculation,
                    priceCategoryName: price.priceCategoryName,
                    productGroupId: price.productGroupId,
                    productGroupName: price.productGroupName,
                    productPriceCategoryId: price.productPriceCategoryId,
                    vat: price.vat
                }
            }),
        description: ""
    }

    // Data used for step where customer register
    const registration = {
        title: t("registrationHeading"),
        newsletterOptionEnabled: newsletterOptionEnabled,
        rubicIntegrationEnabled: rubicIntegrationEnabled,
        registrationFormFreetextField: getTranslationByLocale(product, 'registrationFormFreetextField', locale),
        question: registrationFormQuestion,
        answerAlternatives: registrationFormAnswers,
        multipleChoice: registrationFormMultipleChoice,
        mandatoryQuestion: registrationFormQuestionMandatory,
        isGiftProduct: isGiftProduct,
        description: type === "seasonal_pass" ? t("registrationSubHeading"): t("registrationSubHeadingTickets")
    }

    // Data used in step receipt
    const receipt = {
        title: t("receiptHeading"),
        description: t("receiptSubHeading"),
    }

    // Museuminfo
    const museumInfo = {
        name: museumName,
        id: museumId,
        contact: {
                phone: undefined,
                email: undefined
        },
        address: undefined,
        organizationNumber: undefined,
    }

    return {
        ...event,
        museumInfo,
        calendar,
        tickets,
        registration,
        receipt,
        payTypes,
        type,
        museumSettings,
        hasDiscountCodes,
        converted: true
    }
}

export default getCheckoutFromApiData
