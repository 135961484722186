import {Dialog, DialogActions, DialogContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";

const useStyles = makeStyles(theme => ({
    text: {
        fontFamily: "Akkurat, sans-serif",
    },
    okButton: {
        border: "1px solid",
        display: "flex",
        flexDirection: "column",
        width: "40%",
        margin: theme.spacing(1),
        minHeight: theme.spacing(5),
        color: "black",
        borderColor: "black",
        fontFamily: "Akkurat, sans-serif",
        fontSize: "16px",
        lineHeight: "24px",

        "&:hover": {
            backgroundColor: "black",
            borderColor: "black",
            color: "white"
        },

        "&[data-variant=primary]": {
            fontWeight: "bold",
            borderWidth: "2px",
        },
    }
}));

export const TimeoutDialog = ({open, onClose , timeOutAfter=10}) => {
    const classes = useStyles()
    return <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogContent onClose={onClose}>
            <Typography variant={"h6"} className={classes.text}> Dine billetter er ikke lenger reservert!  &#128560;
            </Typography>
        </DialogContent>
        <DialogContent>
            <Typography className={classes.text}>Siden du brukte over {timeOutAfter} minutter på å fullføre kjøpet ble handlekurven din nullstilt, og du må
                derfor starte bestillingen din på nytt.</Typography>
        </DialogContent>
        <DialogActions>
            <ButtonBase className={classes.okButton} data-variant={'primary'} onClick={onClose}>
                Ok
            </ButtonBase>
        </DialogActions>
    </Dialog>;
}

export default TimeoutDialog;