import React, {useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import Checkout from '../components/Checkout'
import getCart from '../components/utils/getCart'
import {getPasses} from '../components/utils/getPasses'
import {bpnAPI} from '../app/axios.js'

import getCheckoutFromApiData from "../components/utils/getCheckoutFromApiData"
import PageNotFoundView from "./PageNotFoundView"
import moment from "moment";
import Header from "../components/header/Header"
import HeaderExpanded from '../components/header/HeaderExpanded'
import {makeStyles} from "@material-ui/core/styles"
import {useTranslation} from "react-i18next"
import {CheckoutSteps} from "../declarations/Checkoutsteps"
import queryString from "query-string";
import {useProductTranslated} from "../components/product/useProductTranslated";

const useStyles = makeStyles({
    unpublishedBanner: {
        background: "yellow",
        fontFamily: "Akkurat, sans-serif",
        fontSize: 28,
        lineHeight: 1.5,
        textAlign:"center"
    }
})

const CheckoutView = (props) => {
    const classes = useStyles()
    const [productId, setProductId] = useState(null)
    const [date, setDate] = useState(null)
    const [activeCalendarMonth, setActiveCalendarMonth] = useState(moment().startOf('month'))
    const [step, setStep] = useState("")
    const [steps, setSteps] = useState([])
    const [topStep, setTopStep] = useState("")
    const stepIndex = step && steps.indexOf(step)
    const nextStep = steps[stepIndex+1]
    const [payment, setPayment] = useState(null)
    const [moreTicketsAvailable, setMoreTicketsAvailable] = useState(true)
    const [cartById, setCartById] = useState({})
    const [order, setOrder] = useState(null)
    const [generatedOrderLines, setGeneratedOrderLines] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loadingCalendar, setLoadingCalendar] = useState(true)
    const [fetchProductError, setFetchProductError] = useState('')
    const [mostRecentErrorMessage, setMostRecentErrorMessage] = useState('')
    const [summary, setSummary] = useState(null)
    const [cart, setCart] = useState(null)
    const [tickets, setTickets] = useState(null)
    const [lastChangedTicketType, setLastChangedTicketType] = useState({})
    const [orderLinesTickets, setOrderLinesTickets] = useState(null)
    const [fetchedProduct, setFetchedProduct] = useState(null)
    const [currentEvent, setCurrentEvent] = useState({})
    const [passes, setPasses] = useState({})
    const { id } = useParams()
    const { t, ready } = useTranslation('portal')
    const {search} = useLocation();
    const {MEMBER_ID} = queryString.parse(search)
    const queryParams = new URLSearchParams(window.location.search)
    const locale = queryParams.get("lng")

    const translatedProduct = useProductTranslated({
        museumId: fetchedProduct?.museumInfo?.id,
        product: {
            id: id,
            title: fetchedProduct?.title,
            description: fetchedProduct?.description,
            tickets: fetchedProduct?.tickets,
            registration: fetchedProduct?.registration,
            type: fetchedProduct?.type,
            receipt: fetchedProduct?.receipt,
            calendar: fetchedProduct?.calendar
        },
    })

    useEffect(() => {
        if (fetchedProduct && fetchedProduct.type === "seasonal_pass") {
            _onSelect({step: CheckoutSteps.CALENDAR, nextStep: CheckoutSteps.TICKETS})
        }
    }, [fetchedProduct])

    useEffect(() => {
        if (fetchedProduct?.calendar?.events?.length > 0) {
            const eventStart = moment(fetchedProduct?.calendar?.events[0].dtStart.split(" ")[0])
            if (!activeCalendarMonth.isSame(eventStart, 'month')) {
                setActiveCalendarMonth(eventStart)
            }
        }
    }, [fetchedProduct])


    // GET product
    useEffect(() => {
        if (ready) {
            setLoadingCalendar(true)
            bpnAPI.get(`${id}`,
                {
                    params: {
                        'test': `${window._env_.IS_TEST}`,
                        'fetch_unpublished': props.draft,
                        'with_future_schedule_events_only': true,
                        'after': activeCalendarMonth.startOf('month').format('x'),
                        'before': activeCalendarMonth.endOf('month').format('x'),
                    },
                    headers: {
                        "Access-Control-Allow-Origin": "*"
                    }
                }).then(data => {
                setFetchedProduct(getCheckoutFromApiData(data.data.product, id, locale, t))
                const steps = getStepsForProductType(data.data.product.type)
                setSteps(steps)
                setStep(steps[0])
                setTopStep(steps[0])
                setLoading(false)
                setLoadingCalendar(false)
            }).catch(e => {
                setFetchProductError(e)
                setLoading(false)
                setLoadingCalendar(false)
            } );

        }
    }, [id,activeCalendarMonth, ready])

    const getStepsForProductType = (productType) => {
        let steps = []
        if (productType !== "seasonal_pass") {
            steps = [
                CheckoutSteps.CALENDAR,
            ]
        }

        steps = [...steps, CheckoutSteps.TICKETS]
        if (fetchedProduct?.hasAddons) {
            steps = [...steps, CheckoutSteps.ADDONS]
        }

        steps = [...steps, "register", "receipt"]
        return steps
    }

    useEffect(() => {
        // Updates and return summary, cart, tickets and orderLines on each change
        if(translatedProduct && fetchedProduct && ready) {
            const { summary, cart, tickets, orderLinesTickets } = getCart({
                ...fetchedProduct,
                date: date,
                id: productId,
                cartById: cartById,
                productId: id,
                order: order,
                t: t,
                translatedProduct: translatedProduct
            })

            setSummary(summary)
            setCart(cart)
            setTickets(tickets)
            setOrderLinesTickets(orderLinesTickets)
        }
    }, [order, fetchedProduct, date, productId, cartById, id, translatedProduct, ready])

    // Get passes
    useEffect(() => {
        if (fetchedProduct) {
            setPasses(getPasses({
                pass: {
                    logoText: fetchedProduct.location,
                },
                summary: summary,
                cart: cart,
            }))
        }
    }, [summary, fetchedProduct, cart])

    const addGeneratedOrderLineIds = (orderLineTickets, generatedOrderLines) => {
        orderLineTickets.map(orderLine => {
            orderLine.orderLineId = generatedOrderLines.find(dbOrderLine => dbOrderLine.productPriceCategoryId === orderLine.productPriceCategoryId)?.id;
        })
    }

    const updateAvailableTickets = (response) => {
        if (!response.data.remainingTickets[0].limit || response.data.remainingTickets[0].limit === 0) {
            setMoreTicketsAvailable(true);
        } else if (response.data.remainingTickets[0].remainingTicketsAfterPayload < 1) {
            setMoreTicketsAvailable(false);
        } else {
            setMoreTicketsAvailable(!response.data.remainingTickets[0].remainingTicketsAfterPayload < 1);
        }
    }

    const correctCartByIdForAmountError = (putOrderResponse) => {
        const amountOfExtraTickets = (putOrderResponse.data.remainingTickets[0].remainingTicketsAfterPayload*-1)
        const newCorrectedAmountOnLastChanged = cartById[lastChangedTicketType] - amountOfExtraTickets
        setCartById({
            ...cartById,
            [lastChangedTicketType]: newCorrectedAmountOnLastChanged < 0 ? 0 : newCorrectedAmountOnLastChanged
        })
    }

// When orderLines-object change, PUT to backend
    useEffect(() => {
        if (order && orderLinesTickets?.length > 0) {
            if (generatedOrderLines) {
                addGeneratedOrderLineIds(orderLinesTickets, generatedOrderLines)
            }
            bpnAPI.put("orders/" + order.id, {
                orderLines: orderLinesTickets,
                discountCode: order.discountCode
            }).then(response => {
                const maxAmount = response.data.remainingTickets[0]?.maxTicketsPerCustomerLimit
                if (response.data.orderLinesStatus.status === 'NOT OK') {
                    correctCartByIdForAmountError(response);
                    if (response.data.orderLinesStatus.text.toUpperCase().includes('NOT ENOUGH TICKETS')) {
                        setMostRecentErrorMessage(t('noMoreTicketsMessage'))
                    }
                }

                updateAvailableTickets(response);
                setGeneratedOrderLines(response.data.order.orderLines)

                if (response.data.orderLinesStatus.status === 'MAX REACHED') {
                    setMoreTicketsAvailable(false);
                    setCartById({
                        ...cartById,
                        [lastChangedTicketType]: maxAmount
                    })
                    setMostRecentErrorMessage(t('maxPerCustomerReachedMessage', `Du kan bare kjøpe ${maxAmount} billetter av denne typen`))
                }
            }).catch(error => console.error(error))
        }
    }, [order, orderLinesTickets])

    const _onCartChange = ({id, count = 0}) => {
        if (id) {
            setCartById({
                ...cartById,
                [id]: count
            })
            setMostRecentErrorMessage('')
            setLastChangedTicketType(id)
        }
    }

    const onCloseTimeoutDialog = () => {
        setCartById({})
        setMoreTicketsAvailable(true)
        setTopStep('calendar')
        setStep('calendar')
    }

    // onChange
    const _onChange = ({action, ...props}) => {
        if (action === "cart") {
            _onCartChange(props)
        }
        if (action === "payment") {
            setPayment(props.payment)
        }
    }

    const applyDiscountCode = (order, discountCode) => {
        if (order) {
            setOrderLinesTickets(null)
            setOrder({...order, discountCode: discountCode})
        }
    }

    // onSelect -- Metode for gå videre knapp
    const _onSelect = ({id, date, step, nextStep}) => {
        setMostRecentErrorMessage('')
        if (date && id) {
            setProductId(id)
            setDate(date)
            setCartById({})
            setMoreTicketsAvailable(true)
            setTopStep('calendar')
            setCurrentEvent(fetchedProduct.calendar.events.find(item => item.id === id))
        } else if (date) {
            setCurrentEvent({})
            if (date.length === 10) {
                setDate(date)
            } else if (date.length === 7) {
                setDate(null)
            }
            const selectedMomentDate = moment(date,'YYYY-MM')
            if (selectedMomentDate.month() !== activeCalendarMonth.month()) {
                setActiveCalendarMonth(moment(date,'YYYY-MM').startOf('month'))
            }

            setProductId(undefined)
        }
        if (step) {
            if (steps.indexOf(step) <= steps.indexOf(topStep)) {
                setStep(step)
            }

            if (step === 'calendar' && !nextStep && orderLinesTickets.length > 0) {
                bpnAPI.put("orders/" + order.id, {
                    paymentTypeId: null,
                    orderLines: orderLinesTickets,
                    status:'cancelled',
                }).then(response => {
                    setOrder(undefined)
                    console.log(response)
                }).catch( error => console.error(error))
            }
        }
        if (nextStep && step) {
            if (step === CheckoutSteps.CALENDAR) {
                setStep(nextStep)
                if (steps.indexOf(nextStep) > steps.indexOf(topStep)) {
                    setTopStep(nextStep)
                }
                // POST / orders
                bpnAPI.post("orders", {
                        tickets: {},
                        orderLines: [],
                    },
                    {
                        params: {
                            test: `${window._env_.IS_TEST}`,
                            portal: true
                        }
                    }
                ).then(response => {
                    setOrder(response.data.order)
                })
            } else {
                setStep(nextStep)
                if (steps.indexOf(nextStep) > steps.indexOf(topStep)) {
                    setTopStep(nextStep)
                }
            }
        } else if (nextStep) {
            setStep(nextStep)
            if (steps.indexOf(nextStep) > steps.indexOf(topStep)) {
                setTopStep(nextStep)
            }
        }
    }

    if (loading) {
        const logoText = t('logoText')
        return (
            <Header
                logoText={logoText}
                backgroundColor={'#ffffff'}
                expanded={true}
            >
                <HeaderExpanded
                    imageUrl={''}
                    title={logoText}
                    description={''}
                    orderId={''}
                />
            </Header>)
    }

    return (
        <>
            {fetchProductError !== '' ? <PageNotFoundView error={fetchProductError} draft={props.draft}/>:
                <>
                    {props.draft && <div className={classes.unpublishedBanner}>{t('notPublishedWarning')}</div>}
                    <Checkout {...fetchedProduct}
                              translatedProduct={translatedProduct}
                              title={translatedProduct.title}
                              tickets={tickets}
                              passes={passes}
                              mostRecentErrorMessage={mostRecentErrorMessage}
                              step={step}
                              steps={steps}
                              nextStep={nextStep}
                              id={productId}
                              date={date}
                              cart={cart}
                              applyDiscountCode={(order, discountCode) => applyDiscountCode(order.data, discountCode)}
                              order={order}
                              orderLines={orderLinesTickets}
                              payment={payment}
                              summary={summary}
                              paymentTypes={fetchedProduct.paymentTypes}
                              onCartChange={_onCartChange}
                              onChange={_onChange}
                              onSelect={_onSelect}
                              onCloseTimeoutDialog={onCloseTimeoutDialog}
                              moreTicketsAvailable={moreTicketsAvailable}
                              loadingCalendar={loadingCalendar}
                              currentEvent={currentEvent}
                              activeCalendarMonth={activeCalendarMonth}
                              draft={props.draft}
                              rubicInfo={{memberId: MEMBER_ID, prefilled: !!MEMBER_ID}}
                    /></>}
        </>
    )
}

export default CheckoutView
