import React from 'react';
import moment from 'moment';
import "moment/locale/nb";
import "moment/locale/nn";
import "moment/locale/sv";

import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import ArrowBack from "@material-ui/icons/ArrowBack"
import ArrowForward from "@material-ui/icons/ArrowForward"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    label: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: 16,
        textTransform: "capitalize"
    }


}));

const CalendarHeader = ({date, onSelect, hasEarlierEvents, hasLaterEvents}) => {

    const classes = useStyles()
    moment.locale(localStorage.getItem("i18nextLng"))
    const nextMonth = moment(date).add(1, 'months').startOf('month')
    const prevMonth = moment(date).subtract(1, 'months').endOf('month')

    const _onPrevMonth = () => {
        onSelect && onSelect({
            date: moment(prevMonth).format('YYYY-MM')
        })
        
    }

    const _onNextMonth = () => {
        onSelect && onSelect({
            date: moment(nextMonth).format('YYYY-MM')
        })

    }

    const ButtonPrev = () => {
        return (
            <IconButton disabled={!hasEarlierEvents} onClick={_onPrevMonth}><ArrowBack /></IconButton>
        )

    }

    const ButtonNext = () => {
        return (
            <IconButton disabled={!hasLaterEvents} onClick={_onNextMonth}><ArrowForward /></IconButton>
        )
    }

    return (
        <header className={classes.header}>

            <ButtonPrev />

            <Typography className={classes.label}>
                {moment(date).format('MMMM YYYY')}
            </Typography>

            <ButtonNext />

        </header>
    )

}

export default CalendarHeader