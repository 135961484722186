import React, {useState} from 'react';
import BoughtTicket from "./BoughtTicket";


const BoughtTicketList = ({order, productReceiptData}) => {
    const [mainTicketValidationResult, setMainTicketValidationResult] = useState(false);
    const getAssociatedOrderLine = (orderLineId, orderLines) => {
        return orderLines.find(orderLine => {
            return orderLine.id === orderLineId;
        });
    }

    const onMainTicketValidated = (validationResult) => {
        setMainTicketValidationResult(validationResult);
    }

    return (
        <>
            {order.tickets[0]?.mainTicket && order.tickets.length  > 1 && order.orderLines[0].productType !== "seasonal_pass"  &&
                <BoughtTicket isMainTicket={true}
                              mainTicketValidationResult={mainTicketValidationResult}
                              ticket={order.tickets[0].mainTicket}
                              orderLine={getAssociatedOrderLine(order.tickets[0].orderLineId, order.orderLines)}
                              onMainTicketValidated={onMainTicketValidated}
                              order={order}/>}
            {order.tickets.map(ticket => {
                return <BoughtTicket  mainTicketValidationResult={mainTicketValidationResult} key={ticket.id} ticket={ticket}
                                      orderLine={getAssociatedOrderLine(ticket.orderLineId, order.orderLines)}
                                      order={order} productReceiptData={productReceiptData}/>
            })}
        </>
    )
}

export default BoughtTicketList