import React from "react"
import ButtonBase from "@material-ui/core/ButtonBase"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    button: {
        border: "1px solid",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: theme.spacing(9),
        color: "black",
        borderColor: "black",

        //        color: theme.palette.primary.main,

        fontFamily: "Akkurat, sans-serif",
        fontSize: "16px",
        lineHeight: "24px",

        "&:hover": {
            backgroundColor: "black",
            borderColor: "black",
            color: "white"
        },

        "&[data-variant=primary]": {
            fontWeight: "bold",
            borderWidth: "2px"
        },

        "&[data-variant=visa]": {
            backgroundColor: "#0842A6",
            borderColor: "#0842A6",
            color: "white",
            fontWeight: "bold",
        },

        "&[data-variant=vipps]": {
            backgroundColor: "#FF5B24",
            borderColor: "#ff5B24",
            color: "white",
            fontWeight: "bold",
        },

        "&[data-variant=secondary]": {
        },

        "&[disabled]": {
            borderColor: theme.palette.divider,
            color: theme.palette.text.disabled
        },



    },
}));

const CheckoutButton = (props, {variant = "primary"}) => {
    const classes = useStyles()

    return (
        <ButtonBase data-variant={variant} disabled={props.disabled} className={classes.button} onClick={props.onClick}>
            {props.children}
        </ButtonBase>
    )

}


export default CheckoutButton