import React from "react"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown'


const useStyles = makeStyles(theme => ({
    grid: {
//        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "0 auto",
        width: "100%",

        [theme.breakpoints.up('md')]: {
            minHeight: 448,
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: 516,
        },
        [theme.breakpoints.up('xl')]: {
            minHeight: 688,
        },


    },
    media: {
        padding: 0,
        margin: 0,

        [theme.breakpoints.up('md')]: {
            maxWidth: 448,
            margin: 0,

            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",

        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: "none",
            width: 516
        },
        [theme.breakpoints.up('xl')]: {
            width: 688
        },

        "& > img": {
            display: "block",
            width: "100%",
            height: "auto"
        },

    },

    content: {
        padding: theme.spacing(1.5),

        [theme.breakpoints.up('md')]: {
            padding: 0,
        },

    },

    title: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: "1.5em",
        fontWeight: "bold",
        lineHeight: 1.25,
        margin: 0,

        [theme.breakpoints.up('md')]: {
            maxWidth: "14em",
        },

    },
    description: {
        fontFamily: "Akkurat, sans-serif",
        lineHeight: 1.5,
        marginTop: ".5em",
        marginBottom: "1em",

        [theme.breakpoints.up('md')]: {
            maxWidth: "20em",
        },

        "& > *": {
            margin: 0
        },

        "& > * + *": {
            marginTop: ".5em"
        },

    },
    metadata: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: ".825em",
        lineHeight: 1.5,
        flexDirection: "column",
        borderTop: "1px solid",
        paddingTop: theme.spacing(1),

        [theme.breakpoints.up('md')]: {
            maxWidth: "20em",
        },
    }

}));


const EventMedia = ({className, imageUrl}) => {

    return (
        <figure className={className}>
            { imageUrl && <img src={imageUrl}  alt=""/> }
        </figure>
    )
}


const HeaderExpanded = ({imageUrl, title, description, location, orderId}) => {

const classes = useStyles()

return (
        <div className={classes.grid}>
    { imageUrl && <EventMedia imageUrl={imageUrl} className={classes.media} /> }
<div className={classes.content}>
    <Typography className={classes.title}>{title}</Typography>
    <ReactMarkdown className={classes.description} source={description}/>
    <Typography className={classes.metadata}>
        { (orderId && "Ordrenr. " + orderId) || location }
    </Typography>
</div>
</div>
)
}

export default HeaderExpanded