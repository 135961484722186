import React from "react"
import {makeStyles} from '@material-ui/core/styles';
import "../styles/reset.css"
import "../styles/Akkurat.css"
import "../styles/AkkuratMono.css"

import {
    ArticleBase,
    ArticleBody,
    ArticleSidebar,
    ArticleContent,
    ArticleGrid
} from "./article";

import Header from "./header/Header"
import Footer from "./footer/Footer";
import CheckoutSummary from "./CheckoutSummary";
import HeaderExpanded from "./header/HeaderExpanded";
import CheckoutSteps from "./header/CheckoutSteps";


const useStyles = makeStyles(theme => ({

    checkout: {
        width: "100%",
        backgroundColor: "white",
        display: "block",
    },

    sticky: {
        [theme.breakpoints.up('md')]: {

            "& > *": {
                marginTop: theme.spacing(-7),
            }

        },

        "&[data-sticky=false]": {
            position: "sticky",
            top: -96,
            zIndex: 2,


            [theme.breakpoints.up('md')]: {
                top: 0,
                marginTop: theme.spacing(-7),
                marginBottom: theme.spacing(7),

                "& > *": {
                    marginTop: 0,
                }

            }
        }

    },

}));

const CheckoutBase = (props) => {

    const {
        logoText,
        backgroundColor, imageUrl,
        title, description, location,
        orderId,
        expanded,
        onSelect,
        step, steps, nextStep,
        summary = {},
        cart = {},
        sidebar = undefined,
        footer = undefined,
        children,
        currentEvent
    } = props

    const classes = useStyles()

    if (step === "receipt") {
        return (
            <div className={classes.checkout}>
                <ArticleBase id="top">
                    <Header
                        logoText={logoText}
                        backgroundColor={backgroundColor}
                        expanded={expanded}
                    >
                        {expanded ?
                            <HeaderExpanded
                                imageUrl={imageUrl}
                                title={props.translatedProduct.title || title}
                                description={props.translatedProduct.description || description}
                                location={location}
                                orderId={orderId}
                            /> :
                            <CheckoutSteps
                                step={step}
                                steps={steps}
                                logoText={logoText}
                                backgroundColor={backgroundColor}
                                title={props.translatedProduct.title || title}
                                description={props.translatedProduct.description || description}
                                imageUrl={imageUrl}
                                orderId={orderId || ""}
                                expanded={expanded}
                                onSelect={onSelect}
                            />
                        }
                    </Header>
                    <ArticleGrid>
                        <ArticleBody>
                            <ArticleContent>
                                {children}
                            </ArticleContent>
                            <div className={classes.sticky} data-sticky={!expanded}>
                                <ArticleSidebar>
                                    { sidebar }
                                </ArticleSidebar>
                            </div>
                        </ArticleBody>
                        {footer}
                    </ArticleGrid>
                </ArticleBase>
                <Footer logoText={logoText}/>
            </div>
        )
    }

    return (
        <div className={classes.checkout}>
            <ArticleBase id="top">
                <Header
                    logoText={logoText}
                    backgroundColor={backgroundColor}
                    expanded={expanded}
                >
                    {expanded ?
                        <HeaderExpanded
                            imageUrl={imageUrl}
                            title={props.translatedProduct.title || title}
                            description={props.translatedProduct.description || description}
                            location={location}
                            orderId={orderId}
                        /> :
                        <CheckoutSteps
                            step={step}
                            steps={steps}
                            logoText={logoText}
                            backgroundColor={backgroundColor}
                            title={props.translatedProduct.title || title}
                            description={props.translatedProduct.description || description }
                            imageUrl={imageUrl}
                            orderId={orderId || ""}
                            expanded={expanded}
                            onSelect={onSelect}
                        />
                    }
                </Header>
                <ArticleGrid>
                    <ArticleBody>
                        <div className={classes.sticky} data-sticky={!expanded}>
                            <ArticleSidebar>
                                {summary && <CheckoutSummary
                                    {...summary}
                                    cart={cart}
                                    step={step}
                                    nextStep={nextStep}
                                    onSelect={onSelect}
                                    currentEvent={currentEvent}
                                    {...props} />}
                                {sidebar}
                            </ArticleSidebar>
                        </div>
                        <ArticleContent>
                            {children}
                        </ArticleContent>
                    </ArticleBody>
                </ArticleGrid>
            </ArticleBase>
            <Footer logoText={logoText}/>
        </div>
    )

}

export default CheckoutBase

