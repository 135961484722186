import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/nb';
import { makeStyles } from '@material-ui/core/styles';
import {
  ArticleBase,
  ArticleBody,
  ArticleContent,
  ArticleGrid,
} from '../components/article';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { useParams } from 'react-router-dom';
import { useAxiosBpn } from '../app/axios';
import { ProgressIndicator } from '../app/ProgressIndicator';
import {
  Divider,
  Grid,
  IconButton,
  Tooltip,
  LinearProgress,
  Paper,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Update, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    width: '100%',
    backgroundColor: 'white',
    display: 'block',
  },
  progressBar: {
    height: 12,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[300],
  },
  bar: {
    backgroundColor: ({ ticketsLeft }) => {
      if (ticketsLeft === 'few') return '#f9a825';
      if (ticketsLeft === 'none') return '#c62828';
      return '#2e7d32';
    },
  },
  bar2: {
    backgroundColor: ({ ticketsLeft }) => {
      if (ticketsLeft === 'few') return '#ffd95a';
      if (ticketsLeft === 'none') return '#ff5f52';
      return '#60ad5e';
    },
  },
  dashed: {
    display: 'none',
  },
}));

const normalise = (value, max) => (value * 100) / max;

const TicketStatusView = () => {
  moment.locale('nb');
  const classes = useStyles();
  const { parentMuseumId, productId } = useParams();
  const expanded = false;

  const [{ colorHeader, museumName }] = useState({});
  const [eventsGroupedByDate, setEventsGroupedByDate] = useState([]);
  const [lastUpdatedAt, setLastUpdatedAt] = useState(
    moment(new Date()).format('LTS')
  );
  const [after, setAfter] = useState()
  const [before, setBefore] = useState()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [parentMuseumName, setParentMuseumName] = useState()
  const [productName, setProductName] = useState()

  const [{ data, loading }, getTicketStatus] = useAxiosBpn({
    url: `ticket_status`,
    params: {
      parentMuseumId: parentMuseumId,
      productId: productId,
      test: `${window._env_.IS_TEST}`,
      after: after,
      before: before
    }
  });

  useEffect(() => {
    setAfter(moment(selectedDate).startOf('day').valueOf())
    setBefore(moment(selectedDate).endOf('day').valueOf())
  }, [selectedDate])

  useEffect(() => {
    if (data) {
      const {eventsGroupedByDate: _eventsGroupedByDate, parentMuseumName, productName} = data
      setEventsGroupedByDate(_eventsGroupedByDate)
      setParentMuseumName(parentMuseumName)
      setProductName(productName)
    } else {
      setEventsGroupedByDate([])
    }
  }, [data]);

  const handleRefresh = () => {
    getTicketStatus();
    setLastUpdatedAt(moment(new Date()).format('LTS'));
  };

  return loading ? (
    <ProgressIndicator />
  ) : (
    <div className={classes.pageWrapper}>
      <ArticleBase id='top'>
        <Header logoText={museumName}
          backgroundColor={colorHeader || 'red'}
          expanded={expanded}
        >
        </Header>
        <ArticleGrid>
          <ArticleBody>
            <ArticleContent>
              <div style={{ margin: 8 }}>
                <Typography variant={'h5'}>{parentMuseumName || productName}</Typography>
                <Typography variant={'h6'}>
                  Billettstatus
                </Typography>
                <Divider style={{ marginBottom: 16, marginTop: 8 }} />


                <Grid container justify='flex-end' alignItems='baseline'>
                  <Grid item>
                    <Tooltip title={"Forrige dag"}>
                      <IconButton onClick={()=>setSelectedDate(moment(selectedDate).subtract(1, "d"))}>
                        <KeyboardArrowLeft/>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Typography style={{ textAlign: 'right' }}>
                      {moment(selectedDate).format('LL')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip title={"Neste dag"}>
                      <IconButton onClick={()=>setSelectedDate(moment(selectedDate).add(1, "d"))}>
                        <KeyboardArrowRight/>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid
                  container
                  style={{ marginBottom: 16 }}
                  spacing={2}
                  direction='column'
                >
                  {eventsGroupedByDate?.map((group) =>
                    Object.entries(group).map(([date, events]) => (
                      <EventGroup date={date} key={date} events={events} showProductName={!productName} />
                    ))
                  )}
                </Grid>

                <Grid container justify='flex-end' alignItems='baseline'>
                  <Grid item>
                    <Typography variant='caption'>
                      Sist oppdatert: {lastUpdatedAt}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={handleRefresh}>
                      <Update />
                    </IconButton>
                  </Grid>
                </Grid>

              </div>
            </ArticleContent>
          </ArticleBody>
        </ArticleGrid>
        <Footer />
      </ArticleBase>
    </div>
  );
};

export default TicketStatusView;

const EventGroup = ({ date, events, showProductName }) => (
  <Grid item key={date}>
    <Grid container direction='column'>
      {events?.map((event, index) => (
        <Grid item key={index}>
          <TicketInformation showProductName={showProductName} {...event} />
        </Grid>
      ))}
    </Grid>
  </Grid>
);

const TicketInformation = ({
                             productName,
                             name,
                             saleLimit,
                             ticketsReserved,
                             ticketsSold,
                             startDate,
                             startTime,
                             endDate,
                             endTime,
                             isFullDay,
                             showProductName
}) => {
  const [props, setProps] = useState({ ticketsLeft: 'few' });
  const classes = useStyles(props);

  const timeCaption = isFullDay
    ? 'Hele dagen'
    : `${moment(startTime, 'HH:mm:ss').format('HH:mm')}${endTime ? `-${moment(endTime,'HH:mm:ss').format('HH:mm')}` : ''}`;
  const bar1Value = normalise(ticketsSold, saleLimit);
  const bar2Value = normalise(ticketsSold + ticketsReserved, saleLimit);

  useEffect(() => {
    function ticketsLeft(ticketsSold, ticketsReserved, saleLimit) {
      if (!saleLimit) return null;
      let ticketsLeft = saleLimit - (ticketsReserved + ticketsSold);

      if (ticketsLeft) {
        if (saleLimit > 10) {
          if (ticketsLeft <= saleLimit / 10) return 'few';
        } else {
          if (ticketsLeft <= saleLimit / 4) return 'few';
        }
        return 'many';
      } else {
        return 'none';
      }
    }

    setProps({
      ticketsLeft: ticketsLeft(ticketsSold, ticketsReserved, saleLimit),
    });
  }, [ticketsSold, ticketsReserved, saleLimit]);

  function isEventOver(_startDate, _endDate, _endTime) {
    const _date = _endDate ? _endDate : _startDate;
    const _dateTime = _endTime ? `${_date} ${_endTime}` : `${_date} 23:59:59`;

    return !moment(_dateTime, 'YYYY-MM-DD HH:mm:ss').isAfter();
  }

  function getLinearProgress() {
    return saleLimit ? (
      <LinearProgress
        value={bar1Value || 0}
        valueBuffer={bar2Value || 0}
        classes={{
          root: classes.progressBar,
          colorPrimary: classes.colorPrimary,
          bar: classes.bar,
          bar2Buffer: classes.bar2,
          dashed: classes.dashed,
        }}
        variant='buffer'
      />
    ) : null;
  }

  return (
    <Paper
      variant='outlined'
      square
      style={{
        paddingLeft: 8,
        paddingRight: 8,
        margin: 4,
        opacity: isEventOver(startDate, endDate, endTime) ? 0.6 : 1,
        border: '1px solid rgba(0, 0, 0, 0.18)',
      }}
    >

      {showProductName && <Typography variant="button"><strong>{productName}</strong></Typography>}

      <Grid container spacing={1}>
        {name && (
          <Grid item>
            <Typography variant='caption'>
              <strong>{name}</strong>
            </Typography>
          </Grid>
        )}
        {timeCaption && (
          <Grid item>
            <Typography variant='caption'>{timeCaption}</Typography>
          </Grid>
        )}
      </Grid>

      {isEventOver(startDate, endDate, endTime) ? (
        <Typography
          variant='overline'
          style={{ padding: 4, backgroundColor: 'lightgray' }}
        >
          Arrangementet er over
        </Typography>
      ) : (
        getLinearProgress()
      )}
      <Grid container spacing={1}>
        <Grid item>
          <Typography variant='overline'>
            <strong>Solgt: </strong>
            {ticketsSold || 0}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='overline'>
            <strong>Reservert: </strong>
            {ticketsReserved || 0}
          </Typography>
        </Grid>
        {saleLimit ? (
          <Grid item>
            <Typography variant='overline'>
              <strong>Ledig: </strong>
              {saleLimit - ticketsReserved - ticketsSold}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
};
