import React, { useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import CartTable from "./CartTable"
import CartToggle from "./CartToggle"
import {useTranslation} from "react-i18next"

const useStyles = makeStyles(theme => ({
    cart: {
        backgroundColor: "white"
    },
    details: {
        display: "none",

        "&[aria-expanded=true]": {
            display: "block",
        },

        [theme.breakpoints.up('md')]: {
            display: "block",
        }

    },
    summary: {
        display: "block",
    }
    
}));


const Cart = ({collapsible = false, step, sections = [], count, total, footer, details = false}) => {
    const { t } = useTranslation('portal')
    const classes = useStyles()
    const label = t('cartToPay')

    const [expanded, setExpanded] = useState(false)

    const _onToggle = () => {
        setExpanded(!expanded)
    }


    // footer

    if (!footer && (total !== undefined && total !== null)) {

        footer = [
            {
                label: label,
                value: total
            }
        ]
    
    }

    if (!count) {
        return false
    }

    /*

    if (!collapsible) {
        return (
            <div className={classes.cart}>
                <div className={classes.details} aria-expanded={true}>
                    {sections.map((section, index) => <CartTable {...section } key={index} details={details} />)}
                </div>
                <div className={classes.summary}>
                    {count && <CartTable footer={footer} details={details} />}
                </div>
            </div>
        )
    }

    */

    return (
        <div className={classes.cart}>
            <div className={classes.details} aria-expanded={expanded}>
                {sections.map((section, index) => <CartTable {...section } key={index} details={details} />)}
            </div>
            <div className={classes.summary}>
                {count && <CartTable footer={footer} details={details} />}
            </div>
            {collapsible && <CartToggle step={step} expanded={expanded} onClick={_onToggle} /> }
        </div>
    )

}


export default Cart