import React from 'react';
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    statusText: props => ({
        color: 'red',
        width: '80%',
        marginTop: '90px',
        fontSize: '1rem',
        position: 'absolute',
        top: '40%',
        left: '92%',
        padding: theme.spacing(2),
        transform: 'translate(-50%, -50%) rotate(90deg)',
        backgroundColor: props.backgroundColor,
    }),
}));

const IsTestSticker = ({statusText, backgroundColor}) => {
    const classes = useStyles({backgroundColor: backgroundColor})
    return (
        <span className={classes.statusText}>{statusText}</span>
    )
}

export default IsTestSticker