import moment from "moment";


const formatTimeString = (timeString) => {
    return timeString || timeString === '' ? moment(timeString, "HH:mm:ss").format("HH:mm") : '';
}

const formatDateString = (dateString) => {
    return dateString || dateString === '' ?  moment(dateString).format('LL') : '';
}

export const getValidDateTimeForEvent = (orderLine) => {
        const {startTime, startDate, endTime, endDate} = orderLine;
        let resultString = ''
        if (startDate) {
            resultString = formatDateString(startDate)
            if (endDate && startDate !== endDate) {
                resultString = `${resultString} - ${formatDateString(endDate)}`
            }

            if (startTime) {
                resultString = `${resultString}, ${formatTimeString(startTime)}`
                if (endTime &&  startTime !== endTime) {
                    resultString = `${resultString} - ${formatTimeString(endTime)}`
                }
            }
        }

        return resultString
}

export const getValidPeriodSeasonalPass = (productReceiptData, order) => {
    const {validFrom, validTo, daysValid} = productReceiptData;
    let resultString = ''
    if (daysValid) {
        const createdAt = moment(order?.createdAt)
        createdAt.add(daysValid, "days")
        resultString = "t.o.m. " + createdAt.format('DD.MM.YYYY')
    } else {
        const validFromFormatted = moment(validFrom).format('DD.MM.YYYY')
        const validToFormatted = moment(validTo).format('DD.MM.YYYY')
        resultString = validFromFormatted + " - " + validToFormatted

    }

    return resultString
}



