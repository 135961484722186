import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ButtonNext from "./ButtonNext"
import EventDateGrid from "./event/EventDateGrid";
import EventDetails from "./event/EventDetails";
import Cart from "./cart/Cart";
import {useTranslation} from 'react-i18next'


const useStyles = makeStyles(theme => ({
    summary: {
        position: "relative",
        zIndex: 10,
        width: "100%",


        "&[aria-hidden=true]": {
            display: "none"
        },

    },
    header: {
        display: "flex",
        flexDirection: "row"
    },
    footer: {
        backgroundColor: "white",
        padding: theme.spacing(1.5),

        position: "fixed",
        top: "auto",
        left: 0,
        right: 0,
        bottom: 0,

        boxShadow: theme.shadows[4],

        [theme.breakpoints.up('md')]: {
            position: "relative",
            boxShadow: "none"
        }


    },

}));



const CheckoutSummary = ({
    button = {
            label: 'Gå videre',
            onClick: undefined,
        },
    date,
    onSelect,
    nextStep,
    title,
    location,
    id,
    cart,
    step,
    summary,
    type,
    currentEvent
    }) => {

    const classes = useStyles()

    if ((!date && type !== "seasonal_pass") || (type === "seasonal_pass" && !cart.hasTickets)) {
        return false
    }

    const _onSelect = () => {
        onSelect && onSelect({
            nextStep: nextStep,
            step: step
        })
    }

    const SummaryHeader = () => {

        return (
            <header className={classes.header}>
                <EventDateGrid date={date} />
                <EventDetails title={title}
                              location={location}
                              startTime={currentEvent.startTime}
                              endTime={currentEvent.endTime}
                              allDay={currentEvent.allDay}/>
            </header>
        )
    }

    if (!id && !summary?.id) {
        button.disabled = true
    }

    const { isQualified } = cart

    if (step === "tickets" && !isQualified) {
        button.disabled = true
    }

    if (!button.onClick) {
        button.onClick = _onSelect
    }

    const SummaryFooter = ({label, disabled, onClick}) => {
    const { t } = useTranslation('portal')
        if (step === "register") {
            return false
        }

        return (
            <footer className={classes.footer}>
                <ButtonNext disabled={disabled} onClick={onClick}>{t('proceedToCheckout', {label})}</ButtonNext>
            </footer>
        )
    }

    return (
        <Paper className={classes.summary} square elevation={4}>
            { date && <SummaryHeader />}
            { cart && <Cart {...cart} collapsible={true} /> }
            { button && <SummaryFooter {...button} /> }
        </Paper>
    )
}


export default CheckoutSummary