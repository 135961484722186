import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import QRCode from "qrcode.react";

const useStyles = makeStyles(theme => ({
    qrCode: {
        gridArea: 'qrCode',
        justifySelf: 'center',
        marginBottom:theme.spacing(1),
        marginTop:theme.spacing(1),
    },
}));



const LargeQrCodeDialog = ({showLargeQrCode , handleClose, ticketCode}) => {
    const classes = useStyles()
    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={showLargeQrCode}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Vis billett til kontrollør
            </DialogTitle>
            <DialogContent dividers>
                <QRCode className={classes.qrCode} value={ticketCode} renderAs="svg" size={200} />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Steng
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default LargeQrCodeDialog