import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import ProductListAction from "../product/ProductListAction";
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles(theme => ({
    discount: {
        backgroundColor: "#ffe",
        display: "flex",
        alignItems: "center",
    },
    ticket: {
        display: "flex",
        alignItems: "center",
    },
    body: {
        lineHeight: 1.5,
        padding: theme.spacing(1.5),
        flexGrow: 1,
        fontFamily: "Akkurat, sans-serif",
        fontSize: "1em",
    },
    title: {
        fontWeight: "bold"
    },
    description: {
        color: theme.palette.text.secondary
    },
    price: {
        fontFamily: "Akkurat mono",
        fontSize: "1.5em",
        padding: theme.spacing(.5, 1.5)
    },
}));

const TicketListItem = (props) => {
    const classes = useStyles()
    const { title, description, price } = props
    const { t } = useTranslation('portal');
    const priceLabel = (price === 0 && t('priceLabelFree')) || price + ",-"

    if (price < 0) {
        return (
            <div className={classes.discount}>
                <div className={classes.body}>
                    <div className={classes.title}>{title}</div>
                    <div className={classes.description}>{description}</div>
                </div>
                <div className={classes.price}>
                    {priceLabel}
                </div>
            </div>
        )
    }

    return (
        <div className={classes.ticket}>
            <div className={classes.body}>
                { description ?
                    <>
                        <div className={classes.title}>{title}</div>
                        <div className={classes.description}>{description}</div>
                    </> :<div className={classes.title}>{title}</div>  }
                <div className={classes.description}>{priceLabel}</div>
            </div>
            <ProductListAction {...props} />
        </div>
    )
}


export default TicketListItem
