import React from "react"
import EventGridItem from "./EventGridItem"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    grid: {
        marginLeft: theme.spacing(-.5),
        marginRight: theme.spacing(-.5),
    },
    list: {
        display: "flex",
        overflowY: "scroll",

        flexDirection: "row",

        "& > *": {
            marginLeft: theme.spacing(.5),
            marginRight: theme.spacing(.5)
        },

        "& > [aria-selected=true] + [aria-selected=true]": {
            marginLeft: theme.spacing(-.5),
            borderLeftColor: "transparent",
        }

    },

}));

const EventGrid = ({items = [], days = 1, maxItems = undefined, children, onSelect}) => {

    const classes = useStyles({days})

    if (items.length > maxItems) {
        items = items.slice(0, maxItems)
    }

    const _onSelect = (item) => {
        onSelect && onSelect(item)
    }

    return (
        <div className={classes.grid}>
            <div className={classes.list}>
                { items.map((item, index) => {

                    if (days > 1 && items.length > index+1 && item.selected) {
                        items[index+1].selected = true
                    }

                    return (
                        <EventGridItem {...item} onClick={() => _onSelect(item)} key={index} />) }
                    )
                }
                {children}
            </div>
        </div>
    )

}


export default EventGrid