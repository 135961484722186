import getEventDetails from './getEventDetails'

const getCartItems = ({items = [], cartById = {}, order = {}}) => {
    return items.map(item => {
        const {id} = item
        const count = cartById[id] || 0
        let isDiscounted = false
        let totalTicketPrice = count * item.price
        const orderLineForPriceCategory = order?.orderLines?.filter(orderLine => orderLine.productPriceCategoryName === item.title)
        if (orderLineForPriceCategory?.length > 0) {
            totalTicketPrice = orderLineForPriceCategory[0]?.price * count
            isDiscounted = true
        }

        return {
            ...item,
            title: isDiscounted ? `${item.title} (Rabattert   ${(item.price * count) - totalTicketPrice}),-` : item.title,
            total: totalTicketPrice,
            count: count,
            isDiscounted: isDiscounted
        }
    })
}

const cartItemsToOrderLines = ({items = [], cartById = {}, productId, order, summary}) => {
    return items.map(item => {
        const {id} = item
        const count = cartById[id] || 0
        let ticketPrice = item.price
        let isDiscounted = false
        const orderLineForPriceCategory = order?.orderLines?.filter(orderLine => orderLine.productPriceCategoryName === item.priceCategoryName)
        if (orderLineForPriceCategory?.length > 0) {
            ticketPrice = orderLineForPriceCategory[0]?.price
            isDiscounted = true
        }

        return {
            orderId: order?.id,
            productId: parseInt(productId),
            title: item.title,
            price: ticketPrice,
            isDiscounted: isDiscounted,
            priceCategoryId: item.priceCategoryId,
            priceCategoryName: item.priceCategoryName,
            productGroupId: item.productGroupId,
            groupTicket: item.groupTicket,
            productGroupName: item.productGroupName,
            scheduleEventId: summary.scheduleEventId,
            recurNumber: summary.recurNumber,
            productPriceCategoryId: item.productPriceCategoryId,
            vat: item.vat,
            total: count * ticketPrice,
            count: count,
            quantity: count
        }
    })
}


const getCart = ({
                     type,
                     location,
                     title,
                     date,
                     id,
                     calendar = {},
                     cartById = {},
                     productId,
                     order,
                     t,
                     translatedProduct
                 }) => {

    // finne summary basert på id og dato

    let summary = {}
    if (type === 'seasonal_pass') {
        summary = {
            id: 'product-0',
            title: translatedProduct.title,
            location: location
        }
    }

    if (date && id) {
        const event = translatedProduct.calendar?.events?.find(item => item.id === id)
        summary = event ? getEventDetails(event) : undefined
    } else if (date) {
        summary = {
            date: date
        }
    }

    // regne ut antall + total på hver billett

    let ticketItems = getCartItems({items: translatedProduct.tickets?.items, cartById, order})
    let allTickets = cartItemsToOrderLines({items: translatedProduct.tickets?.items, cartById, productId, order, summary})

    // lag handlekurv

    let cartCount = 0
    let cartTotal = 0
    let cartTickets = []
    let orderLines = []
    let cartQualified = false

    // billettene

    ticketItems.forEach(item => {
        if (item.count) {
            cartTickets.push(item)
            cartCount = cartCount + 1
            cartTotal = cartTotal + item.total

            if (item.qualified || item.qualified === undefined) {
                cartQualified = true
            }
        }
    })

    allTickets.forEach(ticket => {
        if (ticket.count) {
            orderLines.push(ticket)
        }
    })

    // sette sammen handlekurv

    let cartSections = []

    if (cartTickets) {
        cartSections.push({
            type: 'tickets',
            title: type === 'seasonal_pass' ? t('yourProducts') : t('yourTickets'),
            items: cartTickets,
            count: cartTickets.length
        })
    }

    const cart = {
        isQualified: cartQualified,
        hasTotal: cartTotal && true,
        hasTickets: cartTickets.length && true, // gjør en sjekk på om det finnes billetter
        sections: cartSections,
        total: cartTotal || 0,
        count: cartCount || 0
    }

    return {
        summary: summary,
        cart: cart,
        tickets: {
            ...translatedProduct.tickets,
            items: ticketItems
        },
        cartTickets: cartTickets,
        orderLinesTickets: orderLines
    }
}

export default getCart