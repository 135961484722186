import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    receiptLine: {
        paddingLeft: '20px',
        paddingRight: '20px',
        fontSize: '1rem',
        display: 'flex',
        justifyContent: 'space-between',
    },
    divider: {
        borderTop: 'dotted',
        borderBottom: 0,
        borderWidth: '2px',
        color:'grey',
    }
}));


const ReceiptLine = ({description, value}) => {
    const classes = useStyles()
    return (
        <>
            <div className={classes.receiptLine}>
                <div>{description}</div>
                <div>{value}</div>
            </div>
            <hr className={classes.divider}/>
        </>
    )
}

export default ReceiptLine