import React from "react"
import SlotListItem from "./SlotListItem"
import { makeStyles } from '@material-ui/core/styles';

import moment from "moment"
moment.locale('nb')


const useStyles = makeStyles(theme => ({
    list: {
        display: "flex",
        flexDirection: "column",

        "& > * + *": {
            marginTop: theme.spacing(1)
        }

    },
}));

const SlotList = ({items = [], date, slot, maxDates = 4, onSelect}) => {

    const classes = useStyles()

    const _onSelect = (item) => {
        onSelect && onSelect(item)
    }

    return (
        <div className={classes.list}>
            { items.map((item, index) => {
                return (
                    <SlotListItem {...item} onClick={() => _onSelect(item)} key={index} />
                )
            })}
        </div>
    )

}


export default SlotList