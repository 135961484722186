import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import IconButton from  "@material-ui/core/IconButton"
import AddIcon from "@material-ui/icons/AddCircleOutline"
import RemoveIcon from "@material-ui/icons/RemoveCircleOutline"

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: "flex",

        "& > *": {
            alignItems: "center",
        },
        color: "inherit"
    },
    content: {
        flexGrow: 1
    },
    action: {
        display: "flex",
        justifyContent: "flex-end"
    },
    count: {
        fontFamily: "Akkurat mono",
        fontSize: "1.5em",
        margin: theme.spacing(.5)
    },
    button: {
        color: "inherit",
        "&[disabled]": {
            color: "inherit",
            opacity: .35
        }
    }
}));


const ProductListAction = (props) => {
    const {
        id,
        capacity = 1000,
        count = 0,
        minCount = 0,
        maxCount,
        onChange,
        children,
        moreTicketsAvailable,
        excludedFromCapacityCalculation
    } = props

    const classes = useStyles()
    const _onChange = ({count}) => {

        if (maxCount && count >= maxCount) {
            count = maxCount
        }
        if (count < minCount) {
            count = minCount
        }

        onChange && onChange({
            id: id,
            count: count,
        })
    }

    const disabled = !capacity

    return (
        <div className={classes.wrapper}>
            <div className={classes.content}>
                {children}
            </div>
            <div className={classes.action}>
                <IconButton className={classes.button} disabled={disabled || count <= minCount} onClick={() => _onChange({count: count-1})}>
                    <RemoveIcon />
                </IconButton>
                <div className={classes.count}>{count}</div>
                <IconButton className={classes.button} disabled={disabled || (!moreTicketsAvailable && !excludedFromCapacityCalculation)} onClick={() => _onChange({count: count+1})}>
                    <AddIcon />
                </IconButton>
            </div>
        </div>
    )
}

export default ProductListAction
