import React from "react"
import EventListItem from "./EventListItem"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    list: {
        display: "flex",
        flexDirection: "column",

        "& > * + *": {
            marginTop: theme.spacing(1)
        },

        "& > [aria-selected=true] + [aria-selected=true]": {
            marginTop: theme.spacing(-.5),
            borderTopColor: "transparent",
        }

    },
}));

const EventList = ({items = [], maxItems, onSelect}) => {

    const classes = useStyles()
    const _onSelect = (item) => {
        onSelect && onSelect(item)
    }

    if (items.length > maxItems) {
        items = items.slice(0, maxItems)
    }


    return (
        <div className={classes.list}>
            { items.map((item, index) => {
                return (
                    <EventListItem {...item}  onClick={() => _onSelect(item)} key={index} />
                )
            })}
        </div>
    )

}


export default EventList