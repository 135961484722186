import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ArticleBase,
  ArticleBody,
  ArticleContent,
  ArticleGrid,
} from '../components/article';
import Header from '../components/header/Header';
import HeaderExpanded from '../components/header/HeaderExpanded';
import Footer from '../components/footer/Footer';
import { bpnAPI } from '../app/axios';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { ProgressIndicator } from '../app/ProgressIndicator';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    width: '100%',
    backgroundColor: 'white',
    display: 'block',
  },
  button: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    borderRadius: 0,
    borderWidth: '2px',
    borderColor: 'black',
    fontFamily: 'Akkurat, sans-serif',
    fontSize: 14,
    width: '100%',
  },
}));

const CancelledOrderView = () => {
  const classes = useStyles();
  const history = useHistory();
  const [headerColor, setHeaderColor] = useState('red');
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const { t } = useTranslation('portal');

  useEffect(() => {
    bpnAPI
      .get(`${id}`, {
        params: {
          with_future_schedule_events_only: true,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((data) => {
        setHeaderColor(data.data.product.colorHeader);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        history.push(``);
        console.log(e);
      });
  }, [id, history]);

  if (loading) {
    return <ProgressIndicator />;
  }

  return (
    <div className={classes.pageWrapper}>
      <ArticleBase id="top">
        <Header
          logoText={t('logoText')}
          backgroundColor={headerColor}
          expanded={true}
        >
          <HeaderExpanded
            imageUrl={''}
            title={t('orderTitleCanceled')}
            description={''}
            orderId={''}
          />
        </Header>
        <ArticleGrid>
          <ArticleBody>
            <ArticleContent>
              <Button
                className={classes.button}
                variant={'outlined'}
                onClick={() => {
                  history.push(`/${id}`);
                }}
              >
                {t('createNewOrder')}
              </Button>
            </ArticleContent>
          </ArticleBody>
        </ArticleGrid>
        <Footer />
      </ArticleBase>
    </div>
  );
};

export default CancelledOrderView;
