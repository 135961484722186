import React from 'react';


const getLhmrSeasonalGiftCardExtraText = () => {
    return <><p>Du som har fått årskort i gave kan også bli medlem i venneforeningen, uten ekstra kostnad.
        Da får du alle medlemsfordeler i Lillehammer Museums Venner.</p>
        <p>Registrer deg på <a href={"https://betaling.lillehammermuseum.no/blivenn"}> https://betaling.lillehammermuseum.no/blivenn/</a></p></>
}


const CustomerSpecificLogic = ({productId}) => {
    switch (productId) {
        case 124:
            return getLhmrSeasonalGiftCardExtraText()
        default:
            return (<></>)
    }
}

export default CustomerSpecificLogic