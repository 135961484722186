import React, {useEffect, useState} from "react"
// noinspection ES6CheckImport
import {useHistory, useLocation} from "react-router-dom"
import {useAxiosBpn} from "./axios"
import {ProgressIndicator} from "./ProgressIndicator"

export const NetaxeptCallback = ({children}) => {
    const history = useHistory()
    const {search} = useLocation()
    const [transactionId, setTransactionId] = useState()
    const [responseCode, setResponseCode] = useState('')
    const locale = localStorage.getItem("i18nextLng")


    const [{loading, error: orderProcessError}, processOrder] = useAxiosBpn({
        url: "orders/process",
        params: {transactionId: transactionId, locale: locale},
        method: "PUT"
    }, {manual: true})

    useEffect(() => {
        const searchParams = new URLSearchParams(search)
        if (searchParams) {
            setTransactionId(searchParams.get("transactionId"))
            setResponseCode(searchParams.get("responseCode"))
        }
    }, [search])

    useEffect(() => {
        if (orderProcessError) {
            console.log(orderProcessError.message)
        }
    }, [orderProcessError])

    useEffect(() => {
        if (transactionId) {
            console.log("Netaxept: callback received", {transactionId: transactionId, responseCode: responseCode})
            console.log("Order process: executing...")
            processOrder().then(({data: {order}}) => {
                if (order.status.toUpperCase().includes('CANCELLED')) {
                    history.push(`/cancelled/${order.orderLines[0].productId}`)
                }

                if (responseCode === "OK") {
                    const {externalId} = order
                    history.push(`/receipt/${externalId}`)
                }
            })
        }
    }, [processOrder, history, responseCode, transactionId])

    return (
        <>
            {loading
                ? <ProgressIndicator/>
                : children
            }
        </>

    )
}
