import React from "react"
import ButtonBase from "@material-ui/core/ButtonBase"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    button: {
        border: "1px solid",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: theme.spacing(9),

        color: "black",

        borderColor: "black",

        //        color: theme.palette.primary.main,

        fontFamily: "Akkurat, sans-serif",
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "24px",

        "&:hover": {
            backgroundColor: "black",
            borderColor: "black",
            color: "white"
        },

        "&[data-variant=vipps]": {
            backgroundColor: "#FF5B24",
            borderColor: "#ff5B24",
            color: "white",
            fontWeight: "bold",


            "&[disabled]": {
                backgroundColor: "transparent",
                borderColor: theme.palette.divider,
                color: theme.palette.text.disabled
            },
    
        },

        "&[disabled]": {
            borderColor: theme.palette.divider,
            color: theme.palette.text.disabled
        },



    },
}));

const CheckoutButton = ({type, variant = "primary", disabled, onClick, children}) => {

    const classes = useStyles()


    return (
        <ButtonBase type={type} data-variant={variant} disabled={disabled} className={classes.button} onClick={onClick}>
            {children}
        </ButtonBase>
    )

}


export default CheckoutButton