import React from "react"

import CheckoutBase from "../CheckoutBase";
import SelectTickets from "../selectTickets/SelectTickets";

const StepTickets = (props) => {

    const {
        tickets = {},
        onCartChange,
        type
    } = props

    return (
        <CheckoutBase {...props} expanded={props.type === "seasonal_pass"}>
            <SelectTickets
                ticketData={tickets}
                type={type}
                onChange={onCartChange}
                mostRecentErrorMessage={props.mostRecentErrorMessage}
                moreTicketsAvailable={props.moreTicketsAvailable}/>
        </CheckoutBase>
    )
}

export default StepTickets