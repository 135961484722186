import React from "react"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";

const useStyles = makeStyles(theme => ({
    details: {
        display: "block",
        flexBasis: 0,
        flexGrow: 1,
        padding: theme.spacing(.5, 1),
        textAlign: "left",
        paddingTop: 4,
        paddingBottom: 4,
        overflow: "hidden",
    },
    title: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: 16,
        lineHeight: 1.5,
        fontWeight: "bold",
    },
    description: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: 16,
        lineHeight: 1.5,
    },
    location: {
        fontFamily: "Akkurat, sans-serif",
        fontSize: 14,
        opacity: .85,
    },
    time: {
    }

}));

const EventDetails = ({className, title, location, startTime, endTime, allDay}) => {
    let formattedStartTime = moment(startTime, "HH:mm:ss").format("HH:mm")
    let formattedEndTime = moment(endTime, "HH:mm:ss").format("HH:mm")

    const classes = useStyles()
    return (
        <div className={className || classes.details}>
            <Typography className={classes.title}>
                { title }
            </Typography>
            { location &&
                <Typography noWrap className={classes.location}>
                    { location }
                </Typography>
            }
            {
                <Typography noWrap className={classes.description}>
                    { startTime && formattedStartTime}
                    {endTime && " - "}
                    {endTime && formattedEndTime}
                </Typography>
            }
        </div>
    )

}

export default EventDetails