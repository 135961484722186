import CustomFieldTemplate from "./CustomFieldTemplate"
import CustomObjectFieldTemplate from "./CustomObjectFieldTemplate"
import Selector from "./Selector";


export default {
    FieldTemplate: CustomFieldTemplate,
    ObjectFieldTemplate: CustomObjectFieldTemplate,
    // Custom:
    Selector: Selector,
}